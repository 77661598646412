.mz-tag-wrapper{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.mz-tag-title{
  @include mz-font-size-height(14px);
  margin-right: mz-get-space-from-base(2);
}

.mz-tag-item{
  @include mz-font-size-height(12px);
  height: mz-get-space-from-base(3.25);
  background: $mz-secondary-light;
  color:#ffffff!important;
  transition: $mz-transition-time-default;
  margin: mz-get-space-from-base(.25);
  padding: 0 mz-get-space-from-base(1.25);
  border-radius: 60px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover{
    background: $mz-secondary-root;
  }
}