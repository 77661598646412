.mz-popup{
  @include mz-position-center;
  @include mz-width-fit-content;
  @include mz-height-fit-content;
  position: fixed;
  background: #ffffff;
  border-radius: 8px;
  z-index: 500;
  color: $mz-gray-root;
  opacity: 0;
  pointer-events: none;
  transition: $mz-transition-time-default;
}

.mz-popup.is-show{
  opacity: 1;
  pointer-events: all;
}

.mz-popup-inner{
  position: relative;
  padding: mz-get-space-from-base(3);
}

.popup-install-addon-des{
  width: 335px;
}

.mz-popup-button-group{
  padding-top: mz-get-space-from-base(2);
  @include display-flex;
  justify-content: space-between;
}

.mz-popup-button-group .mz-btn{
  @include mz-text-size(14px);
}

.mz-popup-close-button{
  position: absolute;
  top:4px;
  right:4px;
  width:mz-get-space-from-base(4);
  height:mz-get-space-from-base(4);
  cursor: pointer;
  @include display-flex;
  justify-content: center;
  font-size: 16px;
  color: $mz-gray-light;
  outline: unset!important;
}

// ######################## Popup install addon #########################

.popup-install-addon-button-readmore{
  color: $mz-primary-root;

  &:hover{
    box-shadow: unset;
  }
}

.popup-install-addon-button-install{
  padding:0 mz-get-space-from-base(2.5);
}

.popup-install-addon{
  max-width: 400px;
  min-width: 300px;
  position: fixed;
  top:unset;
  left: -450px;
  bottom: 32px;
  transform: unset;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 24px 0 rgba(61, 63, 64, 0.2);
  z-index: 500;
  color: #3d3f40;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s;

  @include mz-media-breakpoint-down('md'){
    display: none;
  }
}

.popup-install-addon.is-show{
  left:mz-get-space-from-base(4);
}

// ######################## Popup install addon #########################