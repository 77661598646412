.mz-pagination-wrapper {
  @include mz-make-row;
  margin: 0;
  justify-content: center;
  padding: mz-get-space-from-base(1.75) 0;

  @include mz-media-breakpoint-up('md') {
    margin-top: mz-get-space-from-base(4.25);
  }
}

.mz-pagination-btn {
  width: mz-get-space-from-base(3.5);
  height: mz-get-space-from-base(3.5);
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-radius: 2px;
  border: solid 1px $mz-gray-light;
  @include mz-font-size-height(12px);
  color: $mz-gray-light;
  cursor: pointer;
  transition: $mz-transition-time-default;
  margin: 0 mz-get-space-from-base(0.5);
}

.mz-pagination-btn {
  &:hover, &:active, &.is-active {
    background: $mz-primary-dark !important;
    color: #ffffff !important;
    border: solid 1px $mz-primary-root;
  }
}

.pagination-btn-prev {
  margin-right: mz-get-space-from-base(1.5);
}

.pagination-btn-next {
  margin-left: mz-get-space-from-base(1.5);
}

.nav-links {
  @include mz-make-row;
  margin: 0;
  justify-content: center;
  padding: mz-get-space-from-base(1.75) 0;

  @include mz-media-breakpoint-up('md') {
    margin-top: mz-get-space-from-base(4.25) 0;
  }
}

.page-numbers {
  width: mz-get-space-from-base(3.5);
  height: mz-get-space-from-base(3.5);
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-radius: 2px;
  border: solid 1px $mz-gray-light;
  @include mz-font-size-height(12px);
  color: $mz-gray-light;
  cursor: pointer;
  transition: $mz-transition-time-default;
  margin: 0 mz-get-space-from-base(0.5);

  &.prev {
    margin-right: mz-get-space-from-base(1.5);
  }

  &.next {
    margin-left: mz-get-space-from-base(1.5);
  }

  &:hover, &:active, &.current {
    background: $mz-primary-dark !important;
    color: #ffffff !important;
    border: solid 1px $mz-primary-root;
  }

  &.is-disable {
    opacity: .3;
    cursor: unset;
  }
}