/*
DOC:
- Lấy font-size của thẻ heading theo từng size breakpoint key
- Demo công thức:
  - https://i.imgur.com/oseo36x.png
  - https://docs.google.com/spreadsheets/d/11d-z_Q6deOY4NR3XKqUmNFhFsid8yHLUusmKtRuplhY/edit#gid=1405510374

EXAM:
  mz-heading-font-size('h1','md') => 28px
  mz-heading-font-size('h2') => 16px
*/
@function mz-get-heading-font-size(
  $_heading-key,
  $_breakpoint-key: 'xs',
  $_step-value: $mz-heading-step-value,
  $_map-breakpoint: $mz-map-breakpoint,
  $_map-heading-font-size: $mz-map-heading-font-size,
) {
  @if not(map-get($_map-heading-font-size, $_heading-key))
    or not(map-get($_map-breakpoint,$_breakpoint-key))
    or not(is-absolute-length($_step-value))
  {
    @return null;
  }

  // Bảng mô tả dừng nhảy số từ xl nên trên xl xét breakpoint key về xl
  $_breakpoint-key-max: 'xl';
  @if(mz-compare-breakpoint-key($_breakpoint-key,$_breakpoint-key-max) > 0) {
    $_breakpoint-key: $_breakpoint-key-max;
  }

  // Lấy kích thước cơ bản ở size xs của heading
  $_heading-font-size-base: map-get($_map-heading-font-size,$_heading-key);

  // Update breakpoint key mà ở đó heading bắt đầu thay đổi size
  $_breakpoint-key-base: 'xs';
  @if($_heading-key == 'h4') {
    $_breakpoint-key-base: 'sm';
  } @else if($_heading-key == 'h5') {
    $_breakpoint-key-base: 'md';
  } @else if($_heading-key == 'h6') {
    $_breakpoint-key-base: 'lg';
  }

  // Tìm khoảng cách index giữa key cần lấy và key bắt đầu thay đổi size
  $_breakpoint-key-compare: mz-compare-breakpoint-key($_breakpoint-key,$_breakpoint-key-base);
  @if($_breakpoint-key-compare > 0) {
    @return $_heading-font-size-base + ($_breakpoint-key-compare * $_step-value);
  }

  @return $_heading-font-size-base;
}