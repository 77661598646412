$_map-display: none, inline, inline-block, block, table, table-row, table-cell, flex, inline-flex;

@each $_breakpoint-key in $mz-map-breakpoint-key {
  @include mz-media-breakpoint-up($_breakpoint-key) {
    $_breakpoint-infix-class: mz-get-breakpoint-infix-class($_breakpoint-key);

    @each $_display in $_map-display {
      .mz-d#{$_breakpoint-infix-class}-#{$_display} { display: $_display !important; }
    }
  }
}

