//====== Gray color ======//
$mz-gray-root : #3d3f40;
$mz-gray-light : #899296;
$mz-gray-lighter : #c1c6c9;
$mz-gray-lightest : #e5ebed;

//====== Primary color ======//
$mz-primary-root : #d74b4b;
$mz-primary-dark : #b62c2c;
$mz-primary-light : #eba5a5;

//====== Secondary color ======//
$mz-secondary-root : #596d97;
$mz-secondary-dark : #3f5072;
$mz-secondary-light : #b4c1dc;

//====== Info color ======//
$mz-info-root : #bebebe;
$mz-info-dark : #909090;
$mz-info-light : #e0e0e0;

//====== Success color ======//
$mz-success-root : #4fbf72;
$mz-success-dark : #318d4e;
$mz-success-light : #b9e6c7;

//====== Warning color ======//
$mz-warning-root : #ffd076;
$mz-warning-dark : #ffb019;
$mz-warning-light : #ffebc3;

//====== Danger color ======//
$mz-danger-root : #f36c44;
$mz-danger-dark : #da4f25;
$mz-danger-light : #feb39d;

//Main color
$mz-map-main-color-with-level: (
  'primary': (
    'light': $mz-primary-light,
    'root': $mz-primary-root,
    'dark': $mz-primary-dark,
  ),
  'secondary': (
    'light': $mz-secondary-light,
    'root': $mz-secondary-root,
    'dark': $mz-secondary-dark,
  ),
  'info': (
    'light': $mz-info-light,
    'root': $mz-info-root,
    'dark': $mz-info-dark,
  ),
  'success': (
    'light': $mz-success-light,
    'root': $mz-success-root,
    'dark': $mz-success-dark,
  ),
  'warning': (
    'light': $mz-warning-light,
    'root': $mz-warning-root,
    'dark': $mz-warning-dark,
  ),
  'danger': (
    'light': $mz-danger-light,
    'root': $mz-danger-root,
    'dark': $mz-danger-dark,
  ),
);

// Gray color with level map
$mz-map-gray-color-with-level: (
  'root':$mz-gray-root,
  'light':$mz-gray-light,
  'lighter':$mz-gray-lighter,
  'lightest':$mz-gray-lightest,
)