#wpadminbar {
  display: none !important;
}

//header section
.header-section {
  transition: $mz-transition-time-default;
  will-change: transform;
  position: absolute;
  width: 100%;
  z-index: 500;
}

.header-color-section .header-bottom-block {
  @include mz-get-box-shadow('md');
  border-bottom: solid 1px $mz-gray-lighter;
}

.header-color-section.is-home-page {
  position: fixed;
  width: 100%;
  pointer-events: unset;
  z-index: 900;
}

.fake-space-for-menu {
  // height: 56px;
  height: 80px;

  @include media-breakpoint-up('sm') {
    // height: 80px;
  }

  @include media-breakpoint-up('md') {
    //height: 80px;
  }

  @include media-breakpoint-up('lg') {
    // height: 144px;
    height: 160px;
  }
}

.fake-space-for-menu.is-home-page {
  @include media-breakpoint-up('lg') {
    display: none;
  }
}

//######################### Control header visible ########################//
//#########################################################################//
.header-trans-section {
  display: none;
  position: unset;
}

.header-trans-section.is-home-page {
  display: block;
  @include mz-media-breakpoint-down('md') {
    display: none;
  }
}

.header-color-section {
  position: fixed;
}

.header-color-section.is-home-page {

  @include mz-media-breakpoint-up('lg') {
    position: fixed;
    opacity: 0;
    pointer-events: none;
    transform: translateY(-50px);
  }
}

.header-color-section.is-sticky, .header-color-section.is-home-page.is-sticky {
  transform: translateY(0);
  opacity: 1;
  pointer-events: all;
  position: fixed;
  width: 100%;
  top: 0;
  pointer-events: all;
}

//#########################################################################//
//####################### end control header visible ######################//

.header-wrapper {
  @include display-flex(nowrap);
  justify-content: space-between;
  position: relative;
  height: mz-get-space-from-base(10);
  // height: mz-get-space-from-base(7);
  opacity: 0;

  // @include mz-media-breakpoint-up('sm') {
  //   height: mz-get-space-from-base(10);
  // }
}

.header-wrapper.is-show {
  opacity: 1;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.header-top-block .header-wrapper {
  @include mz-media-breakpoint-up('lg') {
    // height: mz-get-space-from-base(8);
    height: mz-get-space-from-base(10);
  }
}

.header-wrapper .search-flexible {

  @include mz-media-breakpoint-up('lg') {
    border-right: solid 1px $mz-gray-lightest;
    margin-right: mz-get-space-from-base(1);
    padding-right: mz-get-space-from-base(1);
  }

  @include mz-media-breakpoint-up('xl') {
    margin-right: mz-get-space-from-base(2);
    padding-right: mz-get-space-from-base(2);
  }

}

.header-wrapper .search-flexible .search-flexible-bar{
  @include mz-media-breakpoint-only('xs'){
    padding-right: 0;
  }
}

.header-top-block {
  background: $mz-gray-lightest;
  display: none;
  position: relative;
  z-index: 2;

  @include mz-media-breakpoint-up('lg') {
    display: block;
  }
}

.header-top-block__right-col {
  @include mz-make-row;
  margin: 0;
  align-items: center;
  @include mz-text-size($mz-font-size-xs);
  height: 100%;
}

.header-top-block__right-col .mz-dropdown-menu {
  padding: mz-get-space-from-base(3);
  @include mz-text-size(14px);
}

.header-top-block__right-col .mz-dropdown-menu__list-item-outer {
  padding-bottom: mz-get-space-from-base(2) !important;
  margin-bottom: mz-get-space-from-base(2) !important;

  &:last-child {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
}

.header-top-block__right-col .mz-dropdown-menu__list-item {
  padding: 0 0 mz-get-space-from-base(0.5) 0;
}

.right-col-menu {
  padding: 0 mz-get-space-from-base(1);
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
}

.right-col-menu .menu-title-link {
  cursor: pointer;
}

.right-col-menu .menu-title-link a{
  color: $mz-gray-light;
}

.right-col-menu .mz-dropdown-menu {
  overflow: hidden;
  top: 100%;
  z-index: 901;
}

.right-col-menu .mz-dropdown-menu:after {
  position: absolute;
  width: 77px;
  height: 83px;
  transform: rotate(-40deg);
  opacity: 0.5;
  font-size: 88px;
  color: $mz-gray-lightest;
  z-index: -1;
  bottom: 0;
  right: 0;
  bottom: -24px;
  right: -20px;
}

.right-col-menu .mz-dropdown-menu__list-item-outer button {
  margin-bottom: 0;
  margin-top: mz-get-space-from-base(1.5);
}

.right-col-menu-hotline {
  .mz-dropdown-menu {
    &:after {
      @include mz-get-font-awesome-icon('\f2a0', 'light');
      transform: rotate(40deg) scaleX(-1);
      bottom: -20px;
      right: 28px;
    }
  }
}

.right-col-menu-contact {
  .mz-dropdown-menu {
    &:after {
      @include mz-get-font-awesome-icon('\f657', 'light');
    }
  }
}

.right-col-menu-check-order {
  .mz-dropdown-menu {
    z-index: 900;
    &:after {
      @include mz-get-font-awesome-icon('\f571', 'light');
    }
  }
}

.link-contact {
  font-weight: bold;
  color: $mz-primary-root !important;
  padding-top: 0;
}

.link-hotline {
  font-weight: bold;
  color: $mz-primary-root !important;
  padding-top: 0;
  font-size: 20px;
  line-height: mz-get-line-height-from-font-size(20px);
}

.header-top-block__left-col {
  // align-self: flex-end;
}

.header-top-block__left-col .mz-logo {
  width: auto;
  height: 64px;
  position: relative;
  z-index: 1;
}

.header-top-block__left-col .mz-logo-bg {
  position: absolute;
  bottom: -1px;
  left: -24px;
  pointer-events: none;
}

// end .header-top-block

.header-bottom-block {
  background: #ffffff;
  position: relative;
  z-index: 1;
}

.header-bottom-block__wrapper {
  position: relative;
  @include mz-make-row;
  margin: 0;
  align-items: center;
}

// end .header-bottom-block

.menu-desktop {
  display: none;

  @include mz-media-breakpoint-up('lg') {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
  }
;
}

.menu-desktop__list .menu-item {
  margin: 0 mz-get-space-from-base(1.5);
  display: inline-flex;
  height: 100%;
  align-items: center;

  &:first-child {
    margin-left: 0;
  }

  &:hover, &.is-active {

    .menu-title {
      a, i {
        color: $mz-primary-root;

        &:before {
          opacity: 1;
        }
      }
    }
  }
}

.menu-desktop__list .menu-title {
  color: $mz-gray-root;
  font-size: 14px;
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  text-transform: uppercase;
}

.menu-desktop__list .menu-title i {
  margin-left: 4px;
}

.menu-desktop__list .menu-title a {
  color: $mz-gray-root;
  font-weight: bold;
  position: relative;
  transition: $mz-transition-time-default;
  @include display-flex(nowrap);
  justify-content: flex-start;
  height: 100%;

  &:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 4px;
    background: $mz-primary-root;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: 0.5s;
  }

  &:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 15px;
    top: 100%;
    left: 0;
  }
}

// a

.header-trans-section .menu-desktop__list .menu-item .menu-title {
  a, i {
    color: #ffffff;
  }
}

//Hamburger icon
.hamburger-menu {
  width: mz-get-space-from-base(5);
  height: mz-get-space-from-base(7);
  position: relative;
  margin-left: -14px;

  @include mz-media-breakpoint-up('sm') {
    width: mz-get-space-from-base(7);
    height: mz-get-space-from-base(10);
    margin-left: -24px;
  }

  @include mz-media-breakpoint-up('lg') {
    display: none;
  }

  .hamburger-icon {
    @include mz-position-center;
  }
}

//Logo mobile
.logo-mobile {
  height: 100%;
  margin: 0;
  display: flex;
  align-items: center;

  a{
    height: 100%;
    display: flex;
    align-items: center;
  }

  .mz-logo {
    width: 148px;
  }

  @include mz-media-breakpoint-up('sm') {
    display: none;
  }
}

//Header section
.header-section .mz-container {
  @include media-breakpoint-down('md') {
    margin: 0 auto;
  }
}

// Search bar on desktop
.header-section {
  .search-bar {
    display: none;
    position: relative;
    flex-grow: 2;

    @include mz-media-breakpoint-up('sm') {
      display: block;
    }

    @include mz-media-breakpoint-up('lg') {
      display: none;
    }

    .search-bar-icon {
      position: absolute;
      content: "";
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      height: 40px;
      width: 40px;
      @include display-flex;
      justify-content: center;
      cursor: pointer;
      font-size: 18px;

      &:hover {
        color: $mz-primary-root;
      }
    }

    input {
      background: $mz-gray-lightest;
      color: $mz-gray-root;
      border: none !important;
      padding: 0 mz-get-space-from-base(5) 0 mz-get-space-from-base(3.5);
    }
  }
}

// Delivery button
.header-section {
  .delivery-register {
    display: none;

    button {
      border: solid 1px transparent;
    }

    @include mz-media-breakpoint-up('sm') {
      display: block;
    }

    @include mz-media-breakpoint-up('lg') {
      display: none;
    }
  }

  .header-top-block {
    .delivery-register {
      @include mz-media-breakpoint-up('lg') {
        display: block;
      }
    ;
    }
  }
}

.header-section .delivery-register.on-mobile{
  margin: 0 mz-get-space-from-base(1) 0 mz-get-space-from-base(3);
}

// Cart group
.header-section {
  .cart-group {
    @include mz-make-row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 0;
    align-items: center;
    color: $mz-gray-root;
    cursor: pointer;
    position: relative;

    &:before {
      @include mz-media-breakpoint-up('lg'){
        position: absolute;;
        content: "";
        right: 0;
        top: 100%;
        width: 100%;
        height: 40px;
        z-index: -1;
      }
    }
  }

  .cart-group:hover {
    color: $mz-primary-root;

    .cart-icon {
      color: $mz-primary-root;
    }
  }

  .cart-icon {
    padding: mz-get-space-from-base(1.25);
    font-size: 18px;
    color: $mz-gray-root;
  }

  .cart-quantity {
    position: relative;
    display: flex;
    height: mz-get-space-from-base(3);
  }

  .cart-text {
    padding-right: mz-get-space-from-base(1);
    @include mz-media-breakpoint-down('lg') {
      display: none;
    }
    @include mz-media-breakpoint-up('xl') {
      display: block;
    }
  }

  .cart-number {
    border-radius: 30px;
    background: $mz-warning-dark;
    color: #ffffff;
    padding: mz-get-space-from-base(0.5);
    font-size: $mz-font-size-xs;
    line-height: normal;
    min-width: 24px;
    @include display-flex;
    justify-content: center;
  }
}

//####### Header trans section #######//

.header-trans-section {
  position: absolute;
  width: 100%;
  z-index: 2;
}

.header-trans-section {

  .search-mobile {
    display: none;
  }

  .search-flexible {
    display: none;
  }

  .header-top-block {
    background: transparent;
  }

  .header-top-block__right-col a {
    color: #ffffff;
  }

  .header-top-block__left-col {
    align-self: center;
  }

  .mz-logo-bg {
    display: none;
  }

  .header-bottom-block {
    background: transparent;
  }

  .header-bottom-block__wrapper {
    border-bottom: solid 1px rgba(#ffffff, 0.16);
    border-top: solid 1px rgba(#ffffff, 0.16);
  }

  .menu-desktop__list .menu-title a:before {
    background: #ffffff;
    bottom: -5px;
  }

  .header-wrapper {
    // height: mz-get-space-from-base(6.25);
    height: mz-get-space-from-base(10);

    // @include mz-media-breakpoint-up('xl') {
    //   height: mz-get-space-from-base(7);
    // }
  }

  .header-bottom-block .header-wrapper {
    height: mz-get-space-from-base(5.5);
  }

  .cart-info-box{
    top:43px;
  }
}

// Delivery button
.header-trans-section .delivery-register button {
  color: #ffffff;
  border: solid 1px #ffffff;
  background: transparent;
}

.header-trans-section .delivery-register button:hover {
  box-shadow: none;
  background: #ffffff;
  color: $mz-gray-root;
}

// Cart group
.header-trans-section {
  .cart-group {
    margin: 0;
    @include mz-media-breakpoint-up('lg') {
      padding-right: 0;
    }
  ;
    cursor: pointer;
  }

  .cart-group:hover {
    color: $mz-primary-root;
  }

  .cart-icon {
    padding: mz-get-space-from-base(1.25) mz-get-space-from-base(1.5);
    color: #ffffff !important;
  }

  .cart-text {
    padding-right: mz-get-space-from-base(1);
    color: #ffffff;
  }
}

//####### end header trans section #######//

body.is-lock {
  position: fixed;
  overflow: hidden;
}

//############################# Menu Mobile ###############################//
.menu-mobile {
  transition: $mz-transition-time-default;
  height: 100%;
  width: 50vw;
  position: fixed;
  z-index: 1001;
  left: -100vw;
  top: 0;
  opacity: 0;
  pointer-events: none;
  @include mz-media-breakpoint-down('sm') {
    width: 80vw;
  }

}

.menu-mobile.is-show {
  left: 0;
  opacity: 1;
  pointer-events: all;
}

.menu-mobile .register-button-mobile{
  @include mz-media-breakpoint-up('md'){
    display: none!important;
  }
}

.side-menu {
  width: 100%;
  background: #ffffff;
  z-index: 1001;
  @include mz-make-col;
  margin: 0;
  padding: mz-get-space-from-base(3);
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;

  @include mz-media-breakpoint-up('md') {
    padding: mz-get-space-from-base(4);
  }
}

.logo-menu-panel .mz-logo {
  max-width: 208px;

  @include mz-media-breakpoint-up('md') {
    max-width: 220px;
  }
}

.logo-menu-panel {
  border-bottom: solid 1px $mz-gray-lightest;
  padding: mz-get-space-from-base(1) 0 mz-get-space-from-base(4);
}

.side-menu-main {
  border-bottom: solid 1px $mz-gray-lightest;
  padding: mz-get-space-from-base(1) 0 mz-get-space-from-base(2);
}

.side-menu-main .mz-dropdown-menu__inner-space{
  width: 100%;
}

.side-menu-main .mz-dropdown-menu__list-item-outer{
  margin: 0;
  border: unset;
}

.side-menu-main{
  .register-button-mobile{
    width: 100%;
    margin: mz-get-space-from-base(2) 0;
  }
}

// side menu title
.side-menu .menu-title-link {
  display:flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  min-height: 64px;
  border-bottom: solid 2px transparent;
}

.side-menu .menu-title-link a {
  color: $mz-gray-root;
  @include mz-text-size(20px);
  font-weight: bold;
  width:100%;

  &:hover, &.is-active {
    color: $mz-primary-root;
  }

  i {
    padding-right: mz-get-space-from-base(1);
  }
}

.menu-title-link a i {
  padding: 0 mz-get-space-from-base(0.5);
}

.side-menu .menu-title-link button {
  background: transparent;
  border: none;
  width: 32px;
  height: 32px;
  font-size: $mz-font-size-lg;
  color: $mz-gray-light;
  padding: 0;
  text-align: right;

  &:focus {
    outline: none;
  }
}

.side-menu .menu-title-link-text span {
  padding-right: mz-get-space-from-base(1);
}

// on active
.side-menu-item.is-show {

  .menu-title-link {
    position: relative;
    border-bottom: solid 2px $mz-gray-lightest;
    color: $mz-primary-root;

    &:before {
      position: absolute;
      content: "";
      bottom: -1px;
      height: 2px;
      width: mz-get-space-from-base(4);
      background: $mz-primary-root;
    }

    button {
      color: $mz-primary-root;
    }

    a {
      color: $mz-primary-root;
    }
  }
}

// on active

// side menu item
.side-menu-item .mz-dropdown-menu {
  padding: 0 0 0 mz-get-space-from-base(3);

  @include mz-media-breakpoint-up('sm') {
    //padding: 0 mz-get-space-from-base(4);
  }
}

// side-menu-sub
.side-menu-sub {
  padding-top: mz-get-space-from-base(3);
}

.side-menu-sub .mz-dropdown-menu {
  padding: 0;
}

.side-menu-sub .check-order {
  margin-top: mz-get-space-from-base(2);
}

.side-menu-sub .mz-dropdown-menu__list-item-outer {
  margin: mz-get-space-from-base(2) 0;
  padding-bottom: mz-get-space-from-base(2);

  &:last-child {
    margin: 0;
    padding-bottom: 0;
  }
}

.side-menu-sub .mz-dropdown-menu__list-item {
  padding: mz-get-space-from-base(0.25) 0;
}

.mz-dropdown-menu__list-item-outer button {
  font-size: $mz-font-size-xs;
  margin-top: mz-get-space-from-base(2);
}

// Button exit mobile menu
.btn-exit-menu {
  transition: 0.3s;
  position: absolute;
  content: "";
  width: 40px;
  height: 40px;
  right: -45px;
  top: 25px;
  color: white;
  z-index: 1000;
  opacity: 0;
  pointer-events: none;
  font-size: 24px;

  @include mz-media-breakpoint-up('sm') {
    right: -40px;
  }

  @include mz-media-breakpoint-up('md') {
    right: -7vw;
    top: 2vw;
    font-size: 32px;
  }

  .fal, .fas, .far {
    padding: 8px 12px;
  }
}

.btn-exit-menu.is-show {
  opacity: 1;
  pointer-events: all;
}

// End button exit menu

//############################# Menu Mobile ###############################//


.dark-full-background {
  transition: $mz-transition-time-default;
  position: fixed;
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.84);
  z-index: 1000;
  opacity: 0;
  pointer-events: none;
}

.dark-full-background.is-show {
  opacity: 1;
  pointer-events: all;
}

//###################### Flex menu plugin css ########################//
.flex-nav-menu {
  display: inline-flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
  height: 100%;
  align-items: center;
}

.menu-desktop button {
  background: transparent;
  border: unset;
  outline: unset;
}

.header-trans-section .nav__dropdown-wrapper button {
  color: #ffffff !important;
}

.header-trans-section .nav__dropdown-wrapper:before {
  background: #ffffff !important;
  bottom: -4px;
}

.nav__dropdown-wrapper {
  &:before {
    position: absolute;
    content: "";
    height: 4px;
    width: 100%;
    background: $mz-primary-root;
    bottom: 0;
    opacity: 0;
  }

  &::after {
    position: absolute;
    content: "";
    height: 10px;
    width: 200%;
    left: 0;
    bottom: -10px;
  }

  .priority-nav__dropdown {
    visibility: unset;
    position: absolute;
    z-index: 2;
    left: 0;
    min-width: 212px;
    background: #fff;
    top: calc(100% + 8px);
    border-radius: 8px;
    opacity: 0;
    pointer-events: none;
    transition: 0.3s;
    box-shadow: 0 32px 24px -24px rgba(0, 0, 0, 0.4), 0 0 16px 0 rgba(0, 0, 0, 0.1);
    padding: mz-get-space-from-base(1) mz-get-space-from-base(3);
    transform: translateY(8px);
    max-height: 80vh;
    overflow: auto;

    .menu-item {
      display: flex;
      width: 100%;
      padding: 16px 0;
      color: #3d3f40;
      border-radius: 2px;
      margin-bottom: 2px;
      border-bottom: solid 1px #e5ebed;

      &:last-child {
        border-bottom: unset;
      }
    }
  }

  .os-scrollbar-handle, .os-scrollbar-track{
    pointer-events: none;
  }
}

.priority-nav__dropdown a {
  color: $mz-gray-root;

  &:hover {
    color: $mz-primary-root;
  }
}

.nav__dropdown-wrapper:hover {

  button {
    color: $mz-primary-root;
  }

  .priority-nav__dropdown {
    transform: translateY(0px);
    visibility: unset;
    pointer-events: all;
    opacity: 1;
  }

  .os-scrollbar-handle, .os-scrollbar-track{
    pointer-events: auto;
  }

  &:before {
    opacity: 1;
  }
}

.priority-nav__wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  align-items: center;
}

.priority-nav__wrapper i.fal.fa-angle-down {
  transform: rotate(-90deg);
  transition: $mz-transition-time-default;
}

.priority-nav__wrapper button {
  @include display-flex;
  height: 100%;
}

.priority-nav__wrapper .priority-nav__dropdown .mz-dropdown-menu {
  top: unset;
  left: unset;
  position: unset;
  opacity: 0;
  box-shadow: none;
  transform: unset;
  display: none;
  padding: 0 0 0 mz-get-space-from-base(2);
  transition: $mz-transition-time-default;
  min-width: unset;
}

.priority-nav__wrapper .priority-nav__dropdown .menu-item {
  position: relative;
  flex-direction: column;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.priority-nav__wrapper .priority-nav__dropdown .menu-item.is-expand {
  .mz-dropdown-menu {
    display: flex;
    opacity: 1;
    padding-bottom: mz-get-space-from-base(1);
  }

  .menu-title i {
    transform: unset;
  }
}

.priority-nav__wrapper .priority-nav__dropdown .menu-item .menu-title {
  @include display-flex(nowrap);
  justify-content: space-between;
  width: 100%;
  padding: mz-get-space-from-base(2) 0;
  z-index: 10;
  background: #ffffff;
  font-weight: bold;
}

.priority-nav__wrapper .priority-nav__dropdown .menu-item .menu-title:hover {
  a, i {
    color: $mz-primary-root;
  }
}

.priority-nav__wrapper .priority-nav__dropdown .menu-item .menu-title a {
  width: 100%;
}

.priority-nav__wrapper .priority-nav__dropdown .menu-item .mz-dropdown-menu__inner-space {
  padding: 0;
}

.priority-nav__wrapper .priority-nav__dropdown .menu-item .mz-dropdown-menu__list-item-outer {
  margin: 0;
  border-bottom: unset;
}

.priority-nav__wrapper .priority-nav__dropdown .menu-item .mz-dropdown-menu__list-item {
  padding-top: 0;
}

//###################### end Flex menu plugin css ####################//

.menu-header-home-icon{
  margin-left:0 !important;
  @include mz-media-breakpoint-down('md'){
    display:none;
  }
}

.menu-header-home-text{
  @include mz-media-breakpoint-up('lg'){
    display: none;
  }
}