.mz-col-2-10{
  flex: 0 0 20%;
  max-width: 20%;
}

.mz-product-wrap{
  @include mz-make-row;
  margin:0;
  padding: mz-get-space-from-base(5) 0;
}

.mz-product-item{
  padding: mz-get-space-from-base(1.75);
  margin-bottom: mz-get-space-from-base(2.5);
  color:$mz-gray-root;
  transition: $mz-transition-time-default;
}

//============================ Product top ============================//
//=====================================================================//
.mz-product-top{
  @include mz-image-outer;
}

.mz-product-top a{
  @include mz-font-size-height(14px);
  @include mz-image-link;
}

.mz-product-image{
  @include mz-image-img;
}

.mz-product-sale-tag{
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  border-radius: 3px;
  background: $mz-danger-root;
  color: #ffffff;
  padding: 2px 6px;
}
//=========================================================================//
//============================ end Product top ============================//

//============================ Product body ===========================//
//=====================================================================//

.mz-product-title{
  padding: mz-get-space-from-base(1) 0 0;
}

.mz-product-title-link{
  @include mz-get-limit-text-row(14px,2);
  height: mz-get-line-height-from-font-size(14px) * 2;
  transition: $mz-transition-time-default;
  color: $mz-gray-root;
}

//========= Rating =========//
.mz-product-rating{
  @include mz-make-row;
  margin:0;
  align-items: center;
  @include mz-font-size-height(10px);
  color:$mz-gray-lighter;

  i{
    padding-right:mz-get-space-from-base(0.25);
  }
}

.is-star-on{
  color: $mz-warning-dark;
}

.mz-product-review-total{
  color:$mz-gray-light;
}
//========= end Rating =========//

//========= Country =========//
.mz-product-country{
  @include mz-make-row;
  margin:0;
  align-items: center;
}

.mz-product-country-name{
  @include mz-font-size-height(12px);
  color:$mz-gray-root;
}

.mz-product-country-flag{
  padding-left:mz-get-space-from-base(1);
}

.mz-product-country-flag-img{
  width: 100%;
  max-width: 16px;
  max-height: 16px;
}
//========= end Country =========//

//========= Price =========//
.mz-product-price{
  @include mz-make-row;
  margin:0;
  font-family: $mz-font-family-oswald;
  @include mz-font-size-height(16px);
  font-weight: bold;
  color: $mz-warning-dark;
  align-items: center;
}

.mz-product-price-before{
  @include mz-font-size-height(12px);
  font-weight: normal;
  color: $mz-gray-lighter;
  text-decoration: line-through;
  padding-left: mz-get-space-from-base(1);
}
//========= end Price =========//

//========= Button =========//
.mz-product-button{
  padding: mz-get-space-from-base(1) mz-get-space-from-base(1.5);
  height: mz-get-space-from-base(4.25);
  background: transparent;
  transition: $mz-transition-time-default;
}

.mz-product-button-icon{
  padding-right: mz-get-space-from-base(0.5);
}

.mz-btn-add-to-cart{
  border:solid 1px $mz-primary-root;
  color:$mz-primary-root;
}

.mz-btn-quick-view{
  border:solid 1px $mz-gray-lighter;
  color: $mz-gray-lighter;
}
//========= end Button =========//

//============================ end Product body =======================//
//=====================================================================//


//=====================================================================//
//============================ Hover item =======================//

.mz-product-item{
  &:hover{
    .mz-product-title-link{
      color: $mz-primary-root;
    }

    .mz-btn-add-to-cart{
      background: $mz-primary-root;
      color:#ffffff;
      border-color: transparent;
    }
  }
}

//============================ end Hover item =======================//
//=====================================================================//


.mz-product-info-row{
  margin:0;
  justify-content: space-between;
  align-items: center;
  padding:mz-get-space-from-base(1) 0;
}