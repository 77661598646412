 html{
  font-family: $mz-font-family-neurial-grotesk;
  font-size: $mz-font-size-default;
  line-height: mz-get-line-height-from-font-size($mz-font-size-default);
  color: $mz-gray-root;
}

a{
  color: $mz-primary-root;

  &:hover{
    color: $mz-primary-dark;
  }
}

.mz-search-bar-text{
  color:$mz-gray-light!important;
  font-weight: bold!important;

  @include placeholder{
    color:$mz-gray-lighter!important;
    font-weight: bold!important;
  }
  &:focus::placeholder {
    color:$mz-gray-light!important;
  }
}

.mz-form-text-lighter{
  @include placeholder(){
    color:$mz-gray-lighter;
    transition: $mz-transition-time-default;
  }
}

$arrayHeadingSize: 'h1' 'h2' 'h3' 'h4' 'h5' 'h6';

@each $_heading-size in $arrayHeadingSize {
  #{$_heading-size}{
    @include mz-heading-text-size($_heading-size);
    margin: 0;
    padding: 0;
  }
}

@each $_heading-size in $arrayHeadingSize {
  .#{$_heading-size}{
    @include mz-heading-text-size($_heading-size);
    margin: 0;
    padding: 0;
  }
}

figure{
  text-align: center;
}

figcaption{
  @include mz-text-size(14px);
  padding: mz-get-space-from-base(0.5) 0;
  color: $mz-gray-light;
}

.post-item-content-wrapper, .post-detail-section-left-col, .post-main-section{//post detail

  h1{
    @include mz-text-size(28px);
    margin:0;
  }

  h2{
    @include mz-text-size(26px);
    padding-bottom: mz-get-space-from-base(1);
    margin:0;
  }

  h3{
    @include mz-text-size(24px);
    padding-bottom: mz-get-space-from-base(1);
    margin:0;
  }

  h4{
    @include mz-text-size(22px);
    padding-bottom: mz-get-space-from-base(1);
    margin:0;
  }

  h5{
    @include mz-text-size(20px);
    padding-bottom: mz-get-space-from-base(1);
    margin:0;
  }

  h6{
    @include mz-text-size(18px);
    padding-bottom: mz-get-space-from-base(1);
    margin:0;
  }

  a{
    &:hover{
      color: $mz-primary-root;
      text-decoration: underline;
    }
  }

  p,ul{
   margin-bottom: mz-get-space-from-base(2)!important;
  }

  p img{
    margin-top: mz-get-space-from-base(1)!important;
  }
}