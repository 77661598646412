$_map-size: (
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%,
  auto: auto
);

@each $_prop, $_abbrev in (width: w, height: h) {
  @each $_size, $_size-value in $_map-size {
    .mz-#{$_abbrev}-#{$_size} { #{$_prop}: $_size-value !important; }
  }
}

.mz-mw-100 { max-width: 100% !important; }
.mz-mh-100 { max-height: 100% !important; }

.mz-min-vw-100 { min-width: 100vw !important; }
.mz-min-vh-100 { min-height: 100vh !important; }

.mz-vw-100 { width: 100vw !important; }
.mz-vh-100 { height: 100vh !important; }
