@mixin mz-heading-text-size(
  $_heading-key,
  $_map-heading-font-size: $mz-map-heading-font-size,
  $_map-breakpoint: $mz-map-breakpoint
) {
  @if(map-get($_map-heading-font-size, $_heading-key)) {
    @each $_breakpoint-key, $_breakpoint-value in $_map-breakpoint {
      @include mz-media-breakpoint-up($_breakpoint-key) {
        $_heading-font-size: mz-get-heading-font-size($_heading-key,$_breakpoint-key);
        font-size: $_heading-font-size;
        line-height: mz-get-line-height-from-font-size($_heading-font-size);
      }
    }
  }
}

@mixin mz-text-size($_size: 'normal') {
  @if(map-has-key($mz-map-font-size, $_size)) {
    $_font-size: map-get($mz-map-font-size, $_size);
    font-size: $_font-size;
    line-height: mz-get-line-height-from-font-size($_font-size);
  } @else if (map-has-key($mz-map-heading-font-size, $_size)) {
    $_font-size: map-get($mz-map-heading-font-size, $_size);
    font-size: $_font-size;
    line-height: mz-get-line-height-from-font-size($_font-size);
  } @else if (is-absolute-length($_size)) {
    font-size: $_size;
    line-height: mz-get-line-height-from-font-size($_size);
  } @else {
    @error 'mz-text-size: ' + $_size + ' is not valid';
  }
}