.mz-popup-message{
  position: absolute;
  width: 100%;
  min-height: mz-get-space-from-base(8);
  top:120%;
  left:0;
  border-radius: 4px;
  @include display-flex;
  flex-wrap:nowrap;
  padding: mz-get-space-from-base(1.5) mz-get-space-from-base(2);
  border: solid 1px transparent;
}

.mz-popup-message i{
  font-size: 32px;
  color: $mz-gray-root;
}

.mz-popup-message-text-area{
  margin-left: mz-get-space-from-base(2);
  @include mz-text-area-wrap;
}

.mz-popup-message-error{
  background: #fed1c4;
  color: $mz-gray-root;
  border: solid 1px $mz-danger-root;

  i{
    color: $mz-danger-root;
  }
}

.mz-popup-message-success{
  //background: rgba($mz-success-root, 0.3);
  background: #caecd4;
  color: $mz-gray-root;
  border: solid 1px $mz-success-root;

  i{
    color: $mz-success-root
  }
}

.mz-popup-message-form{
  padding: mz-get-space-from-base(1);
  min-height: mz-get-space-from-base(3);
  @include mz-text-size(12px);
}

.mz-popup-message-form i{
  display:none;
}

.mz-popup-message-search{
  padding: mz-get-space-from-base(1) mz-get-space-from-base(2);
  min-height: mz-get-space-from-base(3);
  @include mz-text-size(14px);
  font-weight: bold;
  color: $mz-success-dark;
  @include mz-position-center-x;
  @include mz-width-fit-content;
}

.mz-popup-message-search i{
  font-size: 14px;
}

.mz-popup-message-form .mz-popup-message-text-area{
  margin: 0 mz-get-space-from-base(1);
}

// ######################## Popup add to cart success ####################

.popup-add-to-cart-success{
  width: 100%;
  //max-width: 784px;
  position: absolute;
  left: 0;
  top: 120%;
  background: #ffffff;
  color: $mz-gray-root;
  border: solid 1px $mz-gray-lightest;
  border-radius: 8px;
  z-index: 500;
}

.popup-add-to-cart-success-inner{
  padding: mz-get-space-from-base(4) mz-get-space-from-base(3);
  max-width: 640px;
  margin:auto;

  @include mz-media-breakpoint-up('lg'){
    //padding: mz-get-space-from-base(3) 0;
  }
}

.popup-add-to-cart-success-title{
  @include mz-text-size(14px);
  font-weight: bold;
  color: $mz-success-root;
}

.popup-add-to-cart-success-product{
  padding: mz-get-space-from-base(2) 0;
  @include display-flex;
  align-items: flex-start;
  justify-content: space-between;

  @include mz-media-breakpoint-up('md'){
    flex-wrap: nowrap;
  }
}

.popup-add-to-cart-success-product-image{
  @include mz-image-outer;
  min-width: 64px;
  max-width: 64px;
}

.popup-add-to-cart-success-product-image-link{
  @include mz-image-link;
}

.popup-add-to-cart-success-product-img{
  @include mz-image-img;
}

.popup-add-to-cart-success-product-body{
  margin-left:mz-get-space-from-base(2);
  @include mz-text-size(13px);
  @include display-flex;
  align-items: flex-start;
  width: 66%;
  flex-grow: 2;

  @include mz-media-breakpoint-up('md'){
    margin: 0 mz-get-space-from-base(2);
    max-width: unset;
    min-width: unset;
  }
  @include mz-media-breakpoint-up('')
}

.popup-add-to-cart-success-product-name{
  width: 100%;
  margin-bottom: mz-get-space-from-base(0.5);
  @include mz-get-limit-text-row(13px,2);
}

.popup-add-to-cart-success-product-merchant{
  color: $mz-primary-root;
  margin-right: mz-get-space-from-base(2);
}

.popup-add-to-cart-success-product-paynow {
  width: 100%;
  margin-top: mz-get-space-from-base(2);

  @include mz-media-breakpoint-up('md'){
    margin: unset;
    min-width: 150px;
    max-width: 150px;
  }
}

.popup-add-to-cart-success-product-paynow button{
  width:100%;
}

.popup-add-to-cart-success-control{
  width: 100%;
  @include display-flex(nowrap);
  justify-content: space-between;
  color: $mz-gray-light;
}

.popup-add-to-cart-success-control i{
  //padding-right: mz-get-space-from-base(0.5);
}

.popup-add-to-cart-success-control-delete{
  cursor: pointer;
}

.popup-add-to-cart-success-control-viewcart{
  cursor: pointer;
  color: $mz-primary-root;
}

.popup-add-to-cart-success-control-exit{
  cursor: pointer;
  width: 40px;
  height: 40px;
  font-size: 24px;
  color:$mz-gray-light;
  position: absolute;
  right: mz-get-space-from-base(1);
  top: mz-get-space-from-base(1);
  @include display-flex;
  justify-content: center;
}

// ######################## Popup add to cart success ####################

// ######################## Popup add to cart small ######################

.popup-add-to-cart-small{
  position: absolute;
  top: 110%;
  right: 0;
  border: solid 1px #e5ebed;
  z-index: 500;
  background: #ffffff;
  border-radius: 8px;
  min-width: 300px;
}

.popup-add-to-cart-small-inner{
  padding: mz-get-space-from-base(2);
  @include display-flex(nowrap);
  justify-content: flex-start;
}

.popup-add-to-cart-small-icon{
  font-size: 38px;
  color: $mz-success-root;
  margin-right: mz-get-space-from-base(1);

  @include mz-media-breakpoint-up('md'){
    font-size: 44px;
  }
}

.popup-add-to-cart-small-viewcart button{
  width: 100%;
  margin-top: mz-get-space-from-base(1);
}

// ######################## Popup add to cart small ######################