$_arrViewportShadow: (
  'lg',
  'md',
  'sm',
  'xs',
);
@each $_viewportShadow in $_arrViewportShadow {
  .shadow-#{$_viewportShadow}{
    @include mz-get-box-shadow($_viewportShadow);
  }
}

$_arrViewport: (
  'xxxl',
  'xxl',
  'xl',
  'lg',
  'md',
  'sm',
  'xs',
);
@each $_viewport in $_arrViewport {
  .mz-hidden-#{$_viewport}{
    @include mz-media-breakpoint-only($_viewport){
      display: none;
    }
  }
}

.w-100{
  width: 100%!important;
}

.w-unset{
  width: unset!important;
}

//======= Video popup =======//
.video-segment{
  position: relative;
  width: fit-content;
  width: -moz-fit-content;
  cursor: pointer;
}

.video-img {
  width: 100%;
}

.video-play-btn-outer{
  @include mz-position-center;
  content:"";

  @include mz-media-breakpoint-up('xl'){
  }
}

.video-play-btn{
  position: relative;
  height: 56px;
  width: 56px;

  &:before,&:after{
    @include mz-position-center;
    content:"";
  }

  &:before{
    height: 100%;
    width: 100%;
    border-radius:50%;
    border: solid 4px #ffffff;
  }

  &:after{
    height: 33%;
    width: 33%;
    background: url('../image/general/white-triangle-icon.png');
    background-size: contain;
    left:54%;
  }
}

//======= end Video popup =======//

.is-star-off{
  color:$mz-gray-lightest;
}

.is-star-on{
  color: $mz-warning-dark;
}

.mz-search-button-diagonal{
  position: relative;

  &::before{
    position: absolute;
    content: "";
    left: -4px;
    background: $mz-primary-root;
    width: 300px;
    height: 200px;
    z-index: -1;
    transform: rotate(20deg);
    transition: $mz-transition-time-default;
    display: none;

    @include mz-media-breakpoint-up('md'){
      display: block;
    }
  }

  &:hover{
    &::before{
      background: $mz-primary-dark;
    }
  }
}

.mz-primary-root{
  color: $mz-primary-root;
}

.amazon-logo-small{
  width: 24px;
}

.mz-readonly-white{
  @include mz-readonly{
    background: #ffffff;
  }
}