//======= Dropdown animation =======//
.arrow-down-animation {
  text-align: center;
  height: 50px;
  position: relative;
  font-size: 24px;

  i {
    position: absolute;
    animation: floating 1s ease-in-out infinite;
    color: #ffffff;
  }
}

@-webkit-keyframes floating {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}

@keyframes floating {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}

//======= end Dropdown animation =======//

//######################## Custom class animation ###########################

.input-fadeout {

  input {
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
    z-index: 2;
  }
}

.input-flexible-search-fadeout {

  input {
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
    z-index: 2;
  }

  &:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: $mz-gray-lightest;
    z-index: -1;
    border-radius: 28px;
  }
}

.input-line-fadeout {

  input {
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
    z-index: 2;
  }

  &:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-bottom: solid 1px #c1c6c9;
    z-index: 1;
  }
}

//######################## Custom class animation ###########################

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated-hide-mesage {
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.search-fail-fadein {
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}