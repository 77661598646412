.mz-dropdown {
  position: relative;

  [data-mz-dropdown-target] {
    z-index: 3;
  }
}

.mz-dropdown-menu {
  position: absolute;
  z-index: 2;
  left: 0;
  min-width: 212px;
  background: #fff;
  top: calc(100% + #{mz-get-space-from-base(1)});
  border-radius: $mz-brd-md;
  opacity: 0;
  pointer-events: none;
  transition: $mz-transition-time-default;
  //@include mz-get-box-shadow('xs');
  box-shadow: 0 32px 24px -24px rgba(0, 0, 0, 0.4), 0 0 16px 0 rgba(0, 0, 0, 0.1);
  transform: translateY(mz-get-space-from-base(1));
  padding:mz-get-space-from-base(1) mz-get-space-from-base(2);

  &::before {
    content: '';
    opacity: 0;
    top: -#{mz-get-space-from-base(1)};
    height: mz-get-space-from-base(1);
    left: 0;
    right: 0;
    position: absolute;
  }

  &__inner-space {
    //padding: mz-get-space-from-base(0.5);
  }

  &__seperate-line {
    height: 1px;
    background: $mz-gray-light;
    margin: mz-get-space-from-base(1) 0;
  }

  &.is-show {
    transform: translateY(mz-get-space-from-base(0));
    pointer-events: auto;
    opacity: 1;
  }
}// .mz-dropdown-menu

// =============================================
// List item
// =============================================
.mz-dropdown-menu {
  &__list {
    list-style: none;
  }

  &__list-item-outer {
    margin-bottom: 2px;
    border-bottom: solid 1px $mz-gray-lightest;

    &:last-child {
      margin-bottom: 0;
      border-bottom: none;
    }
  }
}// .mz-dropdown-menu

// Menu item
.mz-dropdown-menu {
  &__list-item {
    display: flex;
    width: 100%;
    padding: mz-get-space-from-base(2) 0;
    color: $mz-text-default;
    border-radius: $mz-brd-xs - 2px;
  }

  &__list-item__icon-col {
    margin-right: mz-get-space-from-base(1);
    min-width: 1px;
    flex: 0 0 16px;
    width: 16px; // Set width để fix tình huống white-space: nowrap ở label không giãn width
    text-align: center;

    &:last-of-type {
      margin-right: 0;
      margin-left: mz-get-space-from-base(1);
    }
  }

  &__list-item__label-col {
    flex: 1 1;
  }

  // Item disabled
  &__list-item.is-disabled {
    color: $mz-text-disabled;
    pointer-events: none;
    background: transparent;
  }

  // Item active
  &__list-item.is-active {
    color: $mz-primary-root;
  }

  &__content-item {
    padding: mz-get-space-from-base(1) mz-get-space-from-base(1.5);
  }
}// .mz-dropdown-menu

.mz-dropdown-menu-insert{
  transition: 0.3s;
  transform: none;
  position: unset;
  border-radius:0;
  box-shadow: none;
  display: none;

  &.is-show{
    display: flex;
  }
}

// A tag list item
a.mz-dropdown-menu__list-item {
  &:hover,&.active {
    //background: $mz-primary-light;
    color: $mz-primary-root;
  }
}

// =============================================
// Position dropdown menu
// =============================================
.mz-dropdown-menu-center {
  left: 50%;
  margin-left: -113px;
}

.mz-dropdown-menu-right {
  right: 0;
  left: auto;
  margin-left: 0;
}