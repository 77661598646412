.cart-info-box {
  position: absolute;
  top: 60px;
  right: 0;
  @include mz-height-fit-content;
  width: 272px;
  color: #3d3f40;
  box-shadow: 0 32px 24px -24px rgba(0,0,0,.4), 0px 20px 16px 0 rgba(0,0,0,.1), inset 0 7px 24px -8px rgba(61,63,64,.1);
  background-color: #fff;
  border-radius: 0 0 8px 8px;
  display: none;
  z-index: 200;

  @include mz-media-breakpoint-down('md'){
    display:none;
  }
}

.cart-info-box-inner {
  padding: mz-get-space-from-base(3);
}

.cart-info-box-des {
  @include mz-text-size(14px);
  font-weight: bold;
}

.cart-info-box-item-head {
  @include display-flex;
  flex-direction: column;
  justify-content: center;
}

.cart-info-box-item {
  padding: mz-get-space-from-base(2) 0 mz-get-space-from-base(1.5);
  border-bottom: solid 1px $mz-gray-lightest;
  @include display-flex(nowrap);
  justify-content: space-between;
  align-items: flex-start;
  color: $mz-gray-light;
  @include mz-text-size(12px);

  &:last-child {
    border-bottom: unset;
  }
}

.cart-info-box-item-image-outer {
  @include mz-image-outer;
  width: 48px;
}

.cart-info-box-item-image-link {
  @include mz-image-link;
}

.cart-info-box-item-img {
  @include mz-image-img;
  padding: 4px;
}

.cart-info-box-item-delete-button {
  font-size: 14px;
  margin-top: mz-get-space-from-base(2);
  cursor: pointer;

  &:hover {
    color: $mz-primary-root;
  }
}

.cart-info-box-item-body {
  flex: 1 1 auto;
  margin-left: mz-get-space-from-base(1);
}

.cart-info-box-item-name {
  @include mz-get-limit-text-row(14px, 2);
  color: $mz-gray-root;
}

.cart-info-box-item-merchant {
  padding: mz-get-space-from-base(0.5) 0;
}

.cart-info-box-item-price {
  padding-top: mz-get-space-from-base(0.5);
}

.cart-info-box-item-price span {
  font-weight: bold;
  color: $mz-gray-root;
}

.cart-info-box-view-cart {
  width: 100%;
}