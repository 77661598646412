.star-rating-wrapper{
  @include display-flex(nowrap);
  color:$mz-info-root;
}

.star-rating-wrapper{
  i{
    @include mz-font-size-height(18px);
    margin: 0 2px;
  }

  .is-star-on{
    color: $mz-warning-dark;
  }
}