// ############################# Breadcumb top #############################
.breadcrumb-block-top{
  display: none;
  background: $mz-gray-lightest;

  @include mz-media-breakpoint-up('md'){
    display: block;
  }
}

.breadcrumb-block-top a{
  color: $mz-gray-root;
}

.breadcrumb-block-list{
  @include mz-make-row;
  margin:0;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  height: mz-get-space-from-base(7.5);
}

.breadcrumb-block-list-item{
  @include mz-font-size-height(12px);
  color:#979797;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin:0 mz-get-space-from-base(0.5);
}

.breadcrumb-block-list-item.is-category{
  color: $mz-secondary-dark;
}

// ########################### Breadcumb bottom #############################
.breadcrumb-block-bottom{
  position: relative;
  text-align: center;
  color: #ffffff;
  width: 100%;
  min-height: 80px;
  overflow: hidden;
  @include display-flex;
  justify-content: center;

  @include mz-media-breakpoint-up('md'){
    min-height: 160px;
  }
}

.breadcrumb-block-bottom-text{
  position: relative;
  z-index: 10;
  padding: mz-get-space-from-base(2) 0;
}

.breadcrumb-block-page-title{
  @include mz-heading-text-size('h4');
  text-align: center;
  font-weight: bold;
  width: 100%;
  text-align: center;
}

.breadcrumb-block-page-title-des{
  @include mz-text-size(14px);
  width: 100%;
  text-align: center;
  display: none;
  margin-top: mz-get-space-from-base(1);

  @include mz-media-breakpoint-up('md'){
    display: block;
  }
}


.breadcrumb-block-bottom-background{
  position: absolute;
  top:0;
  left:0;
  z-index: 9;
  width: 100%;
  height: 100%;
}

.breadcrumb-block-bottom-background-overlay{
  position: absolute;
  background: rgba($mz-primary-root, 0.85);
  width: 100%;
  height: 100%;
  z-index: 2;
}

.breadcrumb-block-bottom-background-image-wrapper{
  @include display-flex;
  justify-content: center;
  width:100%;
  height: 100%;
}

.breadcrumb-block-bottom-background-image-wrapper img{
  position: absolute;
  z-index: 1;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.page-template-cart-page .breadcrumb-block-bottom{
  @include mz-media-breakpoint-only('xs'){
    height: 112px;
  }
}