.home-scroll-menu {
  @include mz-position-center-y;
  position: fixed;
  content: "";
  right: mz-get-space-from-base(1.5);
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  transition: $mz-transition-time-default;

  @include mz-media-breakpoint-up('xl'){
    right: mz-get-space-from-base(2);
  }

  @include mz-media-custom-up(1920px){
    right: mz-get-space-from-base(4);
  }
}

.home-scroll-menu.is-sticky{
  display: none;

  @include mz-media-breakpoint-up('lg'){
    display: block;
    opacity: 1;
    pointer-events: all;
  }
}

.home-scroll-menu-wrapper{
  position: relative;

  @include mz-media-breakpoint-up('lg'){
    &:before{
      position: absolute;
      content: "";
      left:-16px;
      top:-200px;
      height: 100%;
      width: 1px;
      background: $mz-gray-lightest;
      z-index: 0;
      opacity: .5;
      height:100vh;
      top:-33vh;
    }
  }

  @include mz-media-custom-up(1920px){
    &:before{
      left:-32px;
    }
  }
}

.home-scroll-menu-item{
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: mz-get-space-from-base(1) 0;

  &:before{
    @include mz-position-center-y;
    content:"";
    height: 100%;
    width: mz-get-space-from-base(0.5);
    right:calc(100% + 12px);
    background: $mz-gray-root;
    opacity: 0;
    transition: $mz-transition-time-default;
    pointer-events: none;
  }

  @include mz-media-breakpoint-up('xl'){
    &:before{
    }
  }

  @include mz-media-custom-up(1920px){
    &:before{
      right:calc(100% + 27px);
    }
  }
}

.home-scroll-menu-item-icon{
  position: relative;
  border-radius: 50%;
  color:$mz-gray-lighter;
  @include mz-font-size-height(13px);
  font-weight: bold;
  line-height: 32px;
  height: 32px;
  width: 32px;
  text-align: center;
  cursor: pointer;
}

.home-scroll-menu-item-icon{
  i{
    @include mz-position-center;
  }
}

.home-scroll-menu-item-label{
  @include mz-position-center-y;
  content:"";
  width: max-content;
  height: 100%;
  right:calc(100% + 27px);
  pointer-events: none;
  @include mz-font-size-height(12px);
  padding: mz-get-space-from-base(1) mz-get-space-from-base(2.25);
  background:$mz-gray-root;
  color: #ffffff;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  transition: $mz-transition-time-default;
  opacity: 0;
  display:flex;
  align-items: center;
}

.home-scroll-menu-item:hover{
  &:before{
    opacity: 1;
  }

  .home-scroll-menu-item-icon{
    color: $mz-gray-root;
  }

  .home-scroll-menu-item-label{
    opacity:1;
  }
}

.home-scroll-menu-item.is-active{
  &:before{
    opacity: 1;
    background: $mz-primary-root;
  }

  .home-scroll-menu-item-icon{
    background: #ffffff;
    color: $mz-primary-root;
  }
}

.feature-fixed-button-group {
  position: fixed;
  content: "";
  bottom: 110px;
  right: 36px;
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  transition: $mz-transition-time-default;

  @include mz-media-breakpoint-up('xl'){
    right: 40px;
  }

  @include mz-media-custom-up(1920px){
    right: 70px;
  }
}

.feature-fixed-button-group.is-sticky{
  display: none;

  @include mz-media-breakpoint-up('lg'){
    display: flex;
    opacity: 1;
    pointer-events: all;
  }
}

.feature-fixed-button-group{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.feature-fixed-button-group button{
  box-shadow: 0 2px 4px 0 rgba(61, 63, 64, 0.3)!important;
  margin: 12px 0!important;
}

.feature-fixed-button-group .feature-fixed-button-to-top{
  background: #ffffff;
  color: $mz-gray-root;
  border: solid 1px transparent;
  box-shadow: 0 16px 24px 4px rgba(61, 63, 64, 0.1)!important;
}