//############################### Post Info ############################//
.post-info-icon {
  margin-right: mz-get-space-from-base(0.5);
}

.post-info, .post-detail-page-info {
  @include mz-make-row;
  margin: 0;
  align-items: center;
  justify-content: flex-start;

  @include mz-media-breakpoint-up('md') {
    padding: mz-get-space-from-base(1.5) 0 mz-get-space-from-base(2);
    flex-wrap: wrap;
  }
}

.post-info-text, .post-detail-page-info-text {
  @include mz-text-size(12px);
  color: $mz-secondary-dark;
}

.post-info-time, .post-info-total-comment, .post-detail-page-info-time, .post-detail-page-info-total-comment, .post-detail-page-info-total-watch {
  display: none;
  align-items: center;
  margin-right: mz-get-space-from-base(1.75);

  @include mz-media-breakpoint-up('md') {
    display: flex;
  }
}

.post-info-total-comment {

}

.post-info-tag-outer {
  margin-top: mz-get-space-from-base(0.5);
  width: 100%;
}

.post-info-tag, .post-detail-page-info-tag {

  @include mz-media-breakpoint-up('md') {
  }

  @include mz-media-breakpoint-up('xl') {

  }
}

.post-info-tag, .post-detail-page-info-tag {

  a {
    color: $mz-secondary-dark;

    &:hover {
      color: $mz-primary-root;
    }
  }
}

.post-info-tag, .post-detail-page-info-tag {

  span {
    color: $mz-secondary-dark;
    margin: 0 mz-get-space-from-base(.5) 0 0;

    //&:last-child{
    //  display: none;
    //}
  }
}

.post-detail-page-info-tag {
  @include mz-media-breakpoint-up('lg') {
    @include mz-get-limit-text-row(12px, 1);
  }
}

//############################### end Post Info ############################//

//############################### Post relative ############################//
.post-relative {
  width: 100%;
  padding-bottom: mz-get-space-from-base(2.5);

  @include mz-media-breakpoint-up('lg') {
    display: block;
    padding: mz-get-space-from-base(1.25) 0;
  }
}

.post-relative-select {
  padding-bottom: mz-get-space-from-base(1);
  width: 100%;

  @include mz-media-breakpoint-up('md') {
    margin-left: mz-get-space-from-base(2);
  }
}

.post-relative-title {
  position: relative;
  margin: 0 0 mz-get-space-from-base(3);

  @include mz-media-breakpoint-up('lg') {
    @include mz-font-size-height(14px);
    font-weight: bold;
    color: $mz-gray-root;
    margin-bottom: mz-get-space-from-base(1.75);
  }
}

.post-relative-title {
  span {
    @include mz-font-size-height(14px);
    font-weight: bold;
    padding-right: mz-get-space-from-base(.5);
    background: #ffffff;
  }

  .post-relative-title-text {
    @include mz-font-size-height(14px);
    font-weight: bold;
    padding-right: mz-get-space-from-base(.5);
    background: #ffffff;
    display: inline;
  }
}

.post-relative-title {
  &::before {
    position: absolute;
    content: "";
    right: 0;
    bottom: 5px;
    height: 1px;
    width: 100%;
    background: #eba5a5;
    opacity: 0.5;
    z-index: -1;
  }
}

.post-relative-body {

  @include mz-media-breakpoint-up('md') {
    @include display-flex;
    justify-content: space-between;
    margin: 0;
  }

  @include mz-media-breakpoint-up('lg') {
    flex-wrap: nowrap;
    align-items: flex-start;
  }
}

.post-relative-item {
  @include display-flex(nowrap);
  align-items: flex-start;
  justify-content: space-between;
  margin: mz-get-space-from-base(1.25) 0;
  width: 100%;

  @include mz-media-breakpoint-up('md') {
    //margin:mz-get-space-from-base(1.25);
    max-width: 45%;
    min-width: 45%;
  }

  @include mz-media-breakpoint-up('lg') {
    width: unset;
    flex-wrap: wrap;
    max-width: 23%;
    min-width: 23%;
  }
}

.post-relative-item-head {
  @include mz-image-outer-post;
  margin-right: mz-get-space-from-base(2);

  @include mz-media-breakpoint-up('lg') {
    margin-top: mz-get-space-from-base(1.75);
    margin-right: 0;
  }
}

.post-relative-item-title {
  //@include mz-get-limit-text-row(14px,2);
  margin-bottom: mz-get-space-from-base(1);
}

.post-relative-link {
  color: $mz-gray-root;

  &:hover {
    color: $mz-primary-root;
  }
}

.post-relative-item-head-link {
  @include mz-image-link;
}

.post-relative-item-head-link img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.post-relative-item-img {
  @include mz-image-img-post;
}

.post-relative-item-body {
  width: 100%;

  @include mz-media-breakpoint-up('lg') {
    margin-top: mz-get-space-from-base(1.75);
  }
}

.post-title-link {
  color: $mz-gray-root;
}

.post-relative-item-info {
  @include mz-font-size-height(12px);

  @include mz-media-breakpoint-up('lg') {
    display: none;
  }
}

.post-relative-item-info-tag-list {
  @include display-flex;
  @include mz-get-limit-text-row(12px, 1);
}

.post-relative-item-info-tag-list {
  span {
    margin-right: 2px;
  }
}

.post-relative-item-info-tag-list-item {
  color: $mz-secondary-dark;
}

.post-relative-link-group-item {
  @include mz-media-breakpoint-up('lg') {
    @include mz-get-limit-text-row(16px, 1);
    color: $mz-gray-root;
    margin-bottom: mz-get-space-from-base(2.5);
  }
}

.post-relative-link-group {
  padding-left: mz-get-space-from-base(2.75);
}

//############################ end Post relative ############################//