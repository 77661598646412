/*
- mz-grid là hệ thống row, column, offset giúp tạo hệ thống grid tương tự bootstrap
- **Lưu ý: việc sắp xếp thứ tự sinh ra class và key breakpoint để for sinh ra css rất quan trọng, không tự ý thay đổi thứ tự này
*/
$_map-breakpoint-key: $mz-map-breakpoint-key;

// ===================================
// Container
// ===================================
.mz-container {
  width: $mz-container-width-default;
  padding: 0 $mz-side-space-default;
  margin: 0 auto;

  @each $_breakpoint-key in $_map-breakpoint-key {
    $_breakpoint-width: map-get($mz-map-breakpoint, $_breakpoint-key);
    $_side-space-width: map-get($mz-map-side-space, $_breakpoint-key);
    $_container-width: map-get($mz-map-container-width, $_breakpoint-key);

    @include mz-media-breakpoint-up($_breakpoint-key) {
      width: $_container-width;
      padding: 0 $_side-space-width;
    }
  }
}

// ===================================
// Row
// ===================================
.mz-row { // ~ .mz-row-4
  @include mz-make-row();
}

@each $_rate-key, $_rate-value in $mz-map-space-rate {
  .mz-row-#{ $_rate-key } {
    @include mz-make-row($_rate-key);

    > *[class*="mz-col"] {
      padding: 0 #{ mz-get-space-from-base($_rate-value) };
    }
  }
}

// ===================================
// Col
// ===================================
// Kiểm tra và lặp để nối các class col có cùng style giảm tải dung lượng css
$_group-class-col: '.mz-col, .mz-col-auto,';
@for $_col-key from 1 through $mz-grid-total-column {
  $_group-class-col: $_group-class-col + ', .mz-col-' + $_col-key;

  @each $_breakpoint-key in $_map-breakpoint-key {
    $_breakpoint-infix-class: mz-get-breakpoint-infix-class($_breakpoint-key);

    @if($_breakpoint-infix-class != '') {
      // Default
      $_class-col-default: '.mz-col' + $_breakpoint-infix-class;
      @if not(str-index($_group-class-col, $_class-col-default)) {
        $_group-class-col: $_group-class-col + ', ' + $_class-col-default;
      }

      // Responsive
      $_group-class-col: $_group-class-col + ', .mz-col' + $_breakpoint-infix-class + '-' + $_col-key;

      // Auto
      $_class-col-auto: '.mz-col' + $_breakpoint-infix-class + '-auto';
      @if not(str-index($_group-class-col, $_class-col-auto)) {
        $_group-class-col: $_group-class-col + ', ' + $_class-col-auto;
      }
    }
  }
}

#{ $_group-class-col } {
  @include mz-make-col-ready();
}

.mz-col {
  @include mz-make-col();
}

.mz-col-auto {
  @include mz-make-col('auto');
}

// In trước dạng style col chưa responsive
@for $_col-key from 1 through $mz-grid-total-column {
  .mz-col-#{ $_col-key } {
    @include mz-make-col($_col-key);
  }
}

// In tiếp class col dạng responsive sau để ghi đề css được
$_class-styled: '';
@for $_col-key from 1 through $mz-grid-total-column {
  @each $_breakpoint-key in $_map-breakpoint-key {
    $_breakpoint-infix-class: mz-get-breakpoint-infix-class($_breakpoint-key);

    @include mz-media-breakpoint-up($_breakpoint-key) {
      // Default
      $_class-col-default: '.mz-col' + $_breakpoint-infix-class;
      @if not(str-index($_class-styled, $_class-col-default)) {
        $_class-styled: $_class-styled + ', ' + $_class-col-default;
        #{$_class-col-default} {
          @include mz-make-col();
        }
      }

      // Responsive
      .mz-col#{ $_breakpoint-infix-class }-#{ $_col-key } {
        @include mz-make-col($_col-key);
      }

      // Auto
      $_class-col-auto: '.mz-col' + $_breakpoint-infix-class + '-auto';
      @if not(str-index($_class-styled, $_class-col-auto)) {
        $_class-styled: $_class-styled + ', ' + $_class-col-auto;
        #{$_class-col-auto} {
          @include mz-make-col('auto');
        }
      }
    }
  }
}

// ===================================
// Offset
// ===================================
@for $_offset-key from 1 through $mz-grid-total-column {
  $_offset-space: percentage($_offset-key / $mz-grid-total-column);

  .mz-offset-#{ $_offset-key } {
    margin-left: $_offset-space;
  }
}

@for $_offset-key from 1 through $mz-grid-total-column {
  $_offset-space: percentage($_offset-key / $mz-grid-total-column);

  @each $_breakpoint-key in $_map-breakpoint-key {
    @include mz-media-breakpoint-up($_breakpoint-key) {
      .mz-offset-#{ $_breakpoint-key }-#{ $_offset-key } {
        margin-left: $_offset-space;
      }
    }
  }
}
