// mz-get-border-partial
@mixin mz-get-border-bottom($_hex-color){
  border-bottom: solid 1px $_hex-color;
}
@mixin mz-get-border-top($_hex-color){
  border-top: solid 1px $_hex-color;
}
@mixin mz-get-border-left($_hex-color){
  border-left: solid 1px $_hex-color;
}
@mixin mz-get-border-right($_hex-color){
  border-right: solid 1px $_hex-color;
}

// mz-get-border-main-color
@mixin mz-get-border-main-color($_boderColorName,$_boderColorLevel){
  $_hex-main-color: mz-get-main-color-with-level($_boderColorName,$_boderColorLevel);
  @include mz-get-border-top($_hex-main-color);
  @include mz-get-border-bottom($_hex-main-color);
  @include mz-get-border-left($_hex-main-color);
  @include mz-get-border-right($_hex-main-color);
}

// mz-get-border-gray-color
@mixin mz-get-border-gray-color($_boderGrayLevel){
  $_hex-gray-color: mz-get-gray-color-with-level($_boderGrayLevel);
  @include mz-get-border-top($_hex-gray-color);
  @include mz-get-border-bottom($_hex-gray-color);
  @include mz-get-border-left($_hex-gray-color);
  @include mz-get-border-right($_hex-gray-color);
}

// mz-get-border
@mixin mz-get-border($_hex-color){
  @include mz-get-border-top($_hex-color);
  @include mz-get-border-bottom($_hex-color);
  @include mz-get-border-left($_hex-color);
  @include mz-get-border-right($_hex-color);
}

$_viewport-map: (
  'xs': $mz-breakpoint-xs-min,
  'sm': $mz-breakpoint-sm-min,
  'md': $mz-breakpoint-md-min,
  'lg': $mz-breakpoint-lg-min,
  'xl': $mz-breakpoint-lg-min,
);

@mixin mz-get-box-shadow($_viewport-size:'xs',$_box-shadow-color:rgba(61, 63, 64, 0.1)) {
  @if not map-has-key($_viewport-map, $_viewport-size ){
    @error 'List viewport size: xl, lg, md, sm, xs';
  }
  @if($_viewport-size == 'lg' or $_viewport-size == 'xl'){
    box-shadow: 0 16px 24px -16px $_box-shadow-color;
  }
  @if($_viewport-size == 'md'){
    box-shadow: 0 16px 24px -8px $_box-shadow-color;
  }
  @if($_viewport-size == 'sm'){
    box-shadow: 0 8px 16px -4px $_box-shadow-color;
  }
  @if($_viewport-size == 'xs'){
    box-shadow: 0 2px 4px 0 $_box-shadow-color;
  }
}

@mixin mz-get-box-shadow-btn($_box-shadow-btn-color:rgba(61, 63, 64, 0.1)) {
  box-shadow: 0 16px 24px -16px $_box-shadow-btn-color;
}
@mixin mz-get-font-awesome-icon($_codeFont,$_fontWeightName){
  content:'' + $_codeFont;
  font-family: $mz-font-awesome-name;
  font-weight: mz-get-font-awesome-weight($_fontWeightName);
}
@mixin mz-get-font-awesome-brand-icon($_codeFont){
  content:'' + $_codeFont;
  font-family: $mz-font-awesome-brand-name;
}

@mixin mz-position-center{
  position: absolute;
  content: "";
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}

@mixin mz-position-center-x{
  position: absolute;
  content: "";
  left:50%;
  transform: translateX(-50%);
}

@mixin mz-position-center-y{
  position: absolute;
  content: "";
  top:50%;
  transform: translateY(-50%);
}

@mixin mz-get-limit-text-row($_font-size,$_limit){
  $_line-height: mz-get-line-height-from-font-size($_font-size);

  display: -webkit-box;
  font-size: $_font-size;
  line-height: $_line-height;
  max-height: $_line-height*$_limit;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: $_limit;
}

@mixin mz-get-unlimit-text-row($_font-size){
  $_line-height: mz-get-line-height-from-font-size($_font-size);
  font-size: $_font-size;
  line-height: $_line-height;

  display:block;
  max-height: unset;
  -webkit-box-orient: unset;
  overflow: unset;
  text-overflow: unset;
  white-space: unset;
  -webkit-line-clamp: unset;
}

@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}
};

@mixin placeholder-focus {
  &:focus::-webkit-input-placeholder {@content}
  &:focus:-moz-placeholder           {@content}
  &:focus::-moz-placeholder          {@content}
  &:focus:-ms-input-placeholder      {@content}
};

@mixin mz-font-size-with-height($_font-size) {
  font-size: $_font-size;
  line-height: mz-get-line-height-from-font-size($_font-size);
}

@mixin mz-font-size-height($_font-size) {
  font-size: $_font-size;
  line-height: mz-get-line-height-from-font-size($_font-size);
}

@mixin mz-text-height($_font-size) {
  font-size: $_font-size;
  line-height: mz-get-line-height-from-font-size($_font-size);
}

@mixin hide-scrollbar {
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar{
    width: 0!important;
  }
}

@mixin mz-make-col-custom($_percent-rate){
  flex: 0 0 $_percent-rate;
  max-width: $_percent-rate;
}

@mixin display-flex($_wrap:wrap) {
  display: flex;
  flex-wrap:$_wrap;
  align-items: center;
}

@mixin mz-image-outer {
  position: relative;
  width:100%;
  overflow: hidden;
  background: #ffffff;

  &:before{
    display: block;
    content:"";
    padding-bottom: 100%;
  }
}

@mixin mz-image-outer-post {
  position: relative;
  width:100%;
  overflow: hidden;
  background: #ffffff;

  &:before{
    display: block;
    content:"";
    padding-bottom: 60%;
  }
}

@mixin mz-image-link {
  position: absolute;
  width: 100%;
  height: 100%;
  top:0;
  left:0;
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin mz-image-img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

@mixin mz-image-img-post {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@mixin mz-text-area-wrap {
  overflow-wrap: break-word;
  overflow: hidden;
}

@mixin mz-width-fit-content {
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}

@mixin mz-height-fit-content {
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
}

@mixin mz-readonly {
  &:-moz-read-only{@content};
  &:read-only{@content};
}

@mixin mz-hide-scrollbar() {
  &::-webkit-scrollbar {
    display: none;
  }
}