/*
DOC:
- Dùng để tạo css tương tự thể .mz-row trong trường hợp không muốn dùng bộ grid `mz-row`

EXAM:
  @include mz-make-row(1); // ~ margin == -2px
 @include mz-make-row(2); // ~ margin == -4px

 .product-panel-row {
    @include mz-make-row(3); // ~ margin == -6px
 }
*/
@mixin mz-make-row($_space-rate-key: 1, $_map-space-rate: $mz-map-space-rate) {
  $_margin-width: mz-get-space-from-base(map-get($_map-space-rate, $_space-rate-key));
  display: flex;
  flex-wrap: wrap;
  margin-right: -#{$_margin-width};
  margin-left: -#{$_margin-width};
}


/*
DOC:
- Tạo khung css base cho column trong grid, sử dụng kết hợp với row bọc ngoài
*/
@mixin mz-make-col-ready($_space-rate-key: 1, $_map-space-rate: $mz-map-space-rate) {
  $_padding-width: mz-get-space-from-base(map-get($_map-space-rate, $_space-rate-key));
  position: relative;
  // Prevent columns from becoming too narrow when at smaller grid tiers by
  // always setting `width: 100%;`. This works because we use `flex` values
  // later on to override this initial width.
  width: 100%;
  padding-right: $_padding-width;
  padding-left: $_padding-width;
}

/*
DOC:
- Tạo kích thước cho column
- Để tạo column phải đi chung với mz-make-col-ready để tạo css base ban đầu

EXAM:
- mz-make-col(4) => flex: 0 0 25%;
*/
@mixin mz-make-col($_col-size: 'default', $_total-column: $mz-grid-total-column) {
  @if($_col-size == 'default') {
    flex-basis: 0;
    flex-grow: 1;
  } @else if($_col-size == 'auto') {
    flex: 0 0 auto;
    width: auto;
  } @else {
    flex: 0 0 percentage($_col-size / $_total-column);
    // Add a `max-width` to ensure content within each column does not blow out
    // the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
    // do not appear to require this.
    max-width: percentage($_col-size / $_total-column);
  }
}