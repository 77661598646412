//======= Title group =======//
.estimation-cost-title-group{
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding: mz-get-space-from-base(1) 0;
}

.estimation-cost-title{
  @include mz-font-size-height(28px);
  font-weight: bold;
  color: $mz-gray-root;

  @include mz-media-breakpoint-up('md'){
    @include mz-font-size-height(28px);
    max-width: unset;
    text-align: center;
    width: 100%;
  }
  @include mz-media-breakpoint-up('lg'){
    @include mz-font-size-height(32px);
  }
}

.estimation-cost-expand-btn-inner{
  position: absolute;
  top:0;
  left:0;
  min-width: 34px;
  max-width: 34px;
  height: 34px;
  transition: $mz-transition-time-default;

  &:after{
    position: absolute;
    content:"";
    top:16px;
    left:0;
    width: 34px;
    height: 2px;
    background: $mz-gray-light;
  }

  &:before{
    position: absolute;
    content:"";
    top:0;
    left: 16px;
    width: 2px;
    height: 34px;
    background: $mz-gray-light;
  }
}

.estimation-cost-expand-btn{
  width: 34px;
  height: 34px;
  position: relative;

  @include mz-media-breakpoint-up('md'){
    display: none;
  }
}

.estimation-cost-expand-btn.animated{

  .estimation-cost-expand-btn-inner{
    transform: rotate(45deg);
  }
}

.estimation-cost-section-body{
  transition: $mz-transition-time-default;
  height: 0;
  overflow: hidden;

  @include mz-media-breakpoint-up('md'){
    height: auto;
  }
  @include mz-media-custom-up(1920px){
    @include mz-make-row;
    margin:0;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-end;
    height: 120px;
  }
}

.estimation-cost-section-body.is-expand{
  height: auto;
}

.estimation-cost-section-button{
  display: flex;
}

.estimation-cost-section-button{
  .estimation-cost-link{
    display: none;
    @include mz-font-size-height(14px);
    color: $mz-primary-root;
    padding: 0 mz-get-space-from-base(2);

    @include mz-media-breakpoint-up('md'){
      display: flex;
      margin: auto;
    }
  }

  .estimation-cost-link.on-xs{
    display: flex;;
    @include mz-font-size-height(14px);
    font-weight: bold;
    color: $mz-primary-root;
    @include mz-media-breakpoint-up('md'){
      display: none;
    }
    padding: 0; // custom
  }
}
//======= end Title group =======//
//======= Form group =======//
.estimate-form-block{
  @include mz-media-breakpoint-up('md'){
    @include mz-make-row;
    margin:auto;
    justify-content: space-between;
    align-items: center;
  }
  @include mz-media-breakpoint-up('xxxl'){
    flex-wrap: nowrap;
  }
  @include mz-media-custom-up(1920px){
    width: 85%;
  }
}

.estimate-form-group{
  padding-bottom: mz-get-space-from-base(1.5);

  @include mz-media-breakpoint-up('md'){
    width: 48%;
  }
  @include mz-media-breakpoint-up('xxxl'){
    width: 100%;
    margin: 0 mz-get-space-from-base(1);
  }
}

.estimate-form-group-xxxl{

  @include mz-media-custom-up(1920px){
    width:75%;
  }
}

.estimate-member-weight{
  @include mz-media-breakpoint-up('md'){
    width: 48%;
  }
  @include mz-media-breakpoint-up('xxxl'){
    width: 100%;
  }
}

.estimate-member-weight, .estimate-form-weight-inner, .estimate-form-group-size{
  @include mz-make-row;
  margin:0;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.estimate-form-member, .estimate-form-weight{
  width: 47%;
  min-width: 130px;
}

.estimate-form-weight-inner,.estimate-form-group-size{
  border-radius: 4px;
  border:solid 1px $mz-gray-lighter;
  color:$mz-gray-light;
  overflow: hidden;

  .mz-form-control__input, .mz-select-control__input{
    border: none!important;
    border-radius: unset;
  }

  .mz-select-control__input{
    border-left: solid 1px $mz-gray-lighter!important;
    border-top-left-radius: 0!important;
    border-bottom-left-radius: 0!important;
  }
}

.estimate-form-weight-inner-select,.estimate-form-group-size-select{
  width: 100%;
}

.estimate-form-weight-inner-select{
  min-width: 60px;
}

.estimate-form-group-size.mz-form-control, .estimate-form-group-size-select{
  @include mz-make-col(3);
}

.estimate-result-group{
  text-align: center;
  @include mz-font-size-height(16px);
  padding: 0 0 mz-get-space-from-base(2);

  @include mz-media-breakpoint-up('md'){
    @include mz-font-size-height(24px);
  }

  @include mz-media-breakpoint-up('lg'){
    @include mz-font-size-height(28px);
  }
}

.estimate-result-value{
  font-weight: bold;
  color: $mz-primary-root;
  padding-left: mz-get-space-from-base(1);
}

.estimate-form-group-btn{
  @include display-flex(nowrap);
  justify-content: center;
  padding:mz-get-space-from-base(2) 0;
  flex-direction: row-reverse;

  @include mz-media-breakpoint-up('md'){
    flex-direction: row;
  }

  @include mz-media-custom-up(1920px){
    margin: 0;
    flex-wrap: nowrap;
    padding:0 0 mz-get-space-from-base(3.5) mz-get-space-from-base(0.5);
    width: auto;

    button{
      margin:0 mz-get-space-from-base(0.5);
      width: 100%;
    }
  }
}

.estimate-form-group-btn button{
  min-width: 100px;
  max-width: 100px;

  &:first-child{
    margin-left: mz-get-space-from-base(1);
  }

  @include mz-media-breakpoint-up('md'){
    &:first-child{
      margin-left: 0;
      margin-right: mz-get-space-from-base(1);
    }
  }

  @include mz-media-breakpoint-only('xs'){
    width: 47%;
    border-radius: 100px;
  }
}

.estima-form-right-horizontal-line{
  position: relative;

  &:before{
    @include mz-position-center-y;
    content:"";
    height: 20px;
    width: 1px;
    right: 0;
    background: $mz-gray-lightest;
  }
}
//======= end Form group =======//