$mz-font-awesome-name: 'Font Awesome 5 Pro';
$mz-font-awesome-brand-name: 'Font Awesome 5 Brands';
$mz-font-awesome-map-weight: (
  'light': 300,
  'regular':400,
  'solid':900,
);

// Text color
// -------------------
$mz-text-default: $mz-gray-root;
$mz-text-mirnor: $mz-gray-root;
$mz-text-disabled: $mz-gray-light;

// Link Color
// -------------------
$mz-link-color: $mz-primary-root;
$mz-link-hover-color: $mz-primary-dark;
$mz-link-focus-color: $mz-primary-dark;
$mz-link-active-color: $mz-primary-dark;

// Font size / line height for text
// -------------------
$mz-font-size-default: 14px;

$mz-font-size-xs: 12px;
$mz-font-size-sm: 13px;
$mz-font-size-normal: 14px;
$mz-font-size-md: 16px;
$mz-font-size-lg: 18px;
$mz-font-size-default: $mz-font-size-normal;

$mz-map-font-size: (
  'xs': $mz-font-size-xs,
  'sm': $mz-font-size-sm,
  'normal': $mz-font-size-normal,
  'md': $mz-font-size-md,
  'lg': $mz-font-size-lg,
  'default': $mz-font-size-default,
);

// Font size for star rating
// -------------------
$mz-icon-font-size-xs: 10px;
$mz-icon-font-size-sm: 12px;
$mz-icon-font-size-md: 16px;
$mz-icon-font-size-lg: 24px;
$mz-icon-font-size-xl: 32px;

$mz-map-icon-font-size: (
  'xs': $mz-icon-font-size-xs,
  'sm': $mz-icon-font-size-sm,
  'md': $mz-icon-font-size-md,
  'lg': $mz-icon-font-size-lg,
  'xl': $mz-icon-font-size-xl,
);

// Font size / line height for button, form
// -------------------
$mz-btn-font-size-xl: 16px;
$mz-btn-font-size-lg: 14px;
$mz-btn-font-size-md: 14px;
$mz-btn-font-size-sm: 13px;
$mz-btn-font-size-xs: 12px;
$mz-btn-font-size-default: 14px;

$mz-map-btn-font-size: (
  'xl':$mz-btn-font-size-xl,
  'lg':$mz-btn-font-size-lg,
  'md':$mz-btn-font-size-md,
  'sm':$mz-btn-font-size-sm,
  'xs':$mz-btn-font-size-xs,
  'default':$mz-btn-font-size-default,
);

// Font size / line height for button, form
// -------------------
$mz-btn-icon-size-xl: 20px;
$mz-btn-icon-size-lg: 18px;
$mz-btn-icon-size-md: 14px;
$mz-btn-icon-size-sm: 13px;
$mz-btn-icon-size-xs: 12px;
$mz-btn-icon-size-default: 14px;

$mz-map-btn-icon-size: (
  'xl':$mz-btn-icon-size-xl,
  'lg':$mz-btn-icon-size-lg,
  'md':$mz-btn-icon-size-md,
  'sm':$mz-btn-icon-size-sm,
  'xs':$mz-btn-icon-size-xs,
  'default':$mz-btn-icon-size-default,
);

// ######################################################
// Heading font size
// Demo:
// - https://i.imgur.com/oseo36x.png
// - https://docs.google.com/spreadsheets/d/11d-z_Q6deOY4NR3XKqUmNFhFsid8yHLUusmKtRuplhY/edit#gid=1405510374
// ######################################################
$mz-heading-step-value: 4px;
$mz-heading-max-font-size: 48px;
$mz-h1-font-size: 32px;
$mz-h2-font-size: 28px;
$mz-h3-font-size: 24px;
$mz-h4-font-size: 24px;
$mz-h5-font-size: 24px;
$mz-h6-font-size: 24px;

$mz-map-heading-font-size: (
  'h1': $mz-h1-font-size,
  'h2': $mz-h2-font-size,
  'h3': $mz-h3-font-size,
  'h4': $mz-h4-font-size,
  'h5': $mz-h5-font-size,
  'h6': $mz-h6-font-size,
);

// Neurial Grotesk
$_neurial-grotesk-path:'../font/neurial-grotesk/';
@font-face {
  font-family: 'NeurialGrotesk';
  font-style: normal;
  font-weight: 400;
  src:
    local('NeurialGrotesk-Regular'),
    url($_neurial-grotesk-path + 'NeurialGrotesk-Regular.otf');
}

@font-face {
  font-family: 'NeurialGrotesk';
  font-style: italic;
  font-weight: normal;
  src:
    local('NeurialGrotesk-Italic'),
    url($_neurial-grotesk-path + 'NeurialGrotesk-Italic.otf');
}

@font-face {
  font-family: 'NeurialGrotesk';
  font-style: normal;
  font-weight: 700;
  src:
    local('NeurialGrotesk-Bold'),
    url($_neurial-grotesk-path + 'NeurialGrotesk-Bold.otf');
}
// Roboto sans
// ---------
$_roboto-path:'../font/Roboto/';
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src:
    local('Roboto'),
    url($_roboto-path + 'Roboto-Regular.woff2') format('woff2'),
    url($_roboto-path + 'Roboto-Regular.woff2') format('woff'),
    url($_roboto-path + 'Roboto-Regular.ttf');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: normal;
  src:
    local('Roboto Italic'),
    local('Roboto-Italic'),
    url($_roboto-path + 'Roboto-Italic.woff2'),
    url($_roboto-path + 'Roboto-Italic.woff'),
    url($_roboto-path + 'Roboto-Italic.ttf');;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src:
    local('Roboto Medium'),
    local('Roboto-Medium'),
    url($_roboto-path + 'Roboto-Medium.woff2'),
    url($_roboto-path + 'Roboto-Medium.woff'),
    url($_roboto-path + 'Roboto-Medium.ttf');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src:
    local('Roboto Bold'),
    local('Roboto-Bold'),
    url($_roboto-path + 'Roboto-Bold.woff2'),
    url($_roboto-path + 'Roboto-Bold.woff'),
    url($_roboto-path + 'Roboto-Bold.ttf');
}

// Oswald
// ---------
$_oswald-path:'../font/Oswald/';
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  src:
    local('Oswald Regular'),
    local('Oswald-Regular'),
    url($_oswald-path + 'Oswald-Regular.ttf');
}

@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 600;
  src:
    local('Oswald SemiBold'),
    local('Oswald-SemiBold'),
    url($_oswald-path + 'Oswald-SemiBold.ttf');
}

$mz-font-family-neurial-grotesk: 'NeurialGrotesk', 'Oswald', 'Roboto', sans-serif;
$mz-font-family-roboto: 'Roboto', 'Oswald', 'NeurialGrotesk',sans-serif;
$mz-font-family-oswald: 'Oswald', 'Roboto', 'NeurialGrotesk', sans-serif;