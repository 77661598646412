$_btn-width: 40px;

.mz-number-control {
  width: 128px;
  height: $mz-btn-form-height-default;
  position: relative;

  &__input {
    width: 100%;
    padding: 0 #{ mz-get-space-from-base(1) + $_btn-width };
    transition: $mz-transition-time-default;
    background: #fff;
    border: 1px solid $mz-gray-lighter;
    border-radius: $mz-brd-xs;
    height: $mz-btn-form-height-default;
    color: $mz-text-default;
    text-align: center !important;

    // Xóa mũi tên lên xuống
    &[type=number]::-webkit-inner-spin-button,
    &[type=number]::-webkit-outer-spin-button {
      -moz-appearance: none;
      appearance: none;
      -webkit-appearance: none;
    }

    &:disabled, &.is-disabled {
      background: $mz-gray-lightest;
      pointer-events: none;
    }
  }

  @mixin btn-hover {
    background: $mz-primary-light;
    color: $mz-primary-root;
    border-color: $mz-primary-root;
  }

  &__up-btn, &__down-btn {
    position: absolute;
    top: 0;
    height: 100%;
    background: $mz-gray-lightest;
    border: 1px solid $mz-gray-lighter;
    transition: $mz-transition-time-default;
    width: $_btn-width;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      @include btn-hover;
    }

    &.is-disabled, &:disabled {
      pointer-events: none;
      color: $mz-gray-root;
    }
  }

  &__down-btn {
    border-radius: $mz-brd-xs 0 0 $mz-brd-xs;
    left: 0;
  }

  &__up-btn {
    border-radius: 0 $mz-brd-xs $mz-brd-xs 0;
    right: 0;
  }

  // FOCUS TO INPUT
  &__input:focus {
    //border-color: $mz-gray-root;
    //box-shadow: 0 0 0 4px rgba($mz-gray-lighter,0.6);

    &+ .mz-number-control__btn-wrap {
      .mz-number-control__down-btn, .mz-number-control__up-btn {
        //border-color: $mz-gray-root;

        &:hover {
          @include btn-hover;
        }
      }
    }
  }// &__input:focus

  // IS ERROR
  &.is-error {
    .mz-number-control__input {
      border-color: $mz-danger-root;
    }

    .mz-number-control__btn-wrap {
      .mz-number-control__down-btn, .mz-number-control__up-btn {
        border-color: $mz-danger-root;

        &:hover {
          @include btn-hover;
        }
      }
    }// .mz-number-control__btn-wrap
  }// &.is-error
}// .mz-number-control

.mz-number-control__down-btn, .mz-number-control__up-btn{
  outline: none!important;
}

// -------------------
// Size
// -------------------
@each $_breakpoint-key, $_height-value in $mz-map-btn-form-height {
  $_font-size-key: map-get($mz-map-form-font-size-key, $_breakpoint-key);

  .mz-number-control-#{$_breakpoint-key} {
    @include mz-text-size($_font-size-key);
    height: map-get($mz-map-btn-form-height, $_breakpoint-key);

    .mz-number-control__input {
      height: map-get($mz-map-btn-form-height, $_breakpoint-key);
    }
  }
}
