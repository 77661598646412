/*
DOC:
- Sử dụng: https://getbootstrap.com/docs/4.3/utilities/spacing/
*/
@each $_breakpoint-key in $mz-map-breakpoint-key {
  $_breakpoint-infix-class: mz-get-breakpoint-infix-class($_breakpoint-key);

  @include mz-media-breakpoint-up($_breakpoint-key) {
    @each $_rate-key, $_rate-value in $mz-map-space-rate {
      // Padding
      .mz-p#{$_breakpoint-infix-class}-#{$_rate-key} { padding: mz-get-space-from-base($_rate-value) !important; }
      .mz-px#{$_breakpoint-infix-class}-#{$_rate-key} { padding: 0 mz-get-space-from-base($_rate-value) !important; }
      .mz-py#{$_breakpoint-infix-class}-#{$_rate-key} { padding: mz-get-space-from-base($_rate-value) 0 !important; }
      .mz-pt#{$_breakpoint-infix-class}-#{$_rate-key} { padding-top: mz-get-space-from-base($_rate-value) !important; }
      .mz-pr#{$_breakpoint-infix-class}-#{$_rate-key} { padding-right: mz-get-space-from-base($_rate-value) !important; }
      .mz-pb#{$_breakpoint-infix-class}-#{$_rate-key} { padding-bottom: mz-get-space-from-base($_rate-value) !important; }
      .mz-pl#{$_breakpoint-infix-class}-#{$_rate-key} { padding-left: mz-get-space-from-base($_rate-value) !important; }
    }
  }
}