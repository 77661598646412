.table-scroll {
  position: relative;
  width:100%;
  z-index: 1;
  margin: auto;
  overflow-y: hidden;
  overflow-x: scroll;
  padding-bottom: mz-get-space-from-base(1);
}

.table-scroll thead{
  text-align: center;

  @include mz-media-breakpoint-up('lg'){
    text-align: left;
  }
}

.table-scroll{

  thead:after {
    @include mz-media-breakpoint-up('xl'){
      content: "-";
      display: table-row;
      line-height: 1em;
      color: transparent;
    }
  }

  th:first-child,td:first-child {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 2;
    text-align: left;

    @include mz-media-breakpoint-down('lg'){
      box-shadow: inset -2px 0 0px #00000038;
    }
  }

  thead th:first-child,
  tfoot th:first-child {
    z-index: 2;
  }

  thead th:first-child{
    background: #ffffff!important;
  }

}

.table-scroll tbody tr{
  position: relative;
  z-index: 0;

  &:nth-child(even){
    border-radius: 4px;

    td{
      background: #f4f4f4!important;
    }
  }
  &:nth-child(odd){
    border-radius: 4px;

    td{
      background: #ffffff!important;
    }
  }
}

.table-scroll tbody tr.hidden-row{
  transition: $mz-transition-time-default;
  display: none;
}

.table-scroll table {
  width: 100%;
  margin: auto;
  border-collapse: collapse;
  border-spacing: 0;
}

.table-wrap {
  position: relative;
}

.table-scroll th,
.table-scroll td {
  padding: 5px 10px;
  vertical-align: top;
  text-align: center;
  border: 1px solid transparent!important;

  @include mz-media-breakpoint-down('lg'){
    border: unset!important;
  }
}

.table-scroll thead th {
  @include mz-font-size-height(14px);
  font-weight: bold;
  background:transparent;
  color: $mz-gray-root;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  min-width: 160px;
  border-bottom: solid 1px #89929640 !important;
}

.table-scroll thead th:after{
  display: table-row;
  content: "-";
  color:transparent;
}

.table-scroll tfoot,
.table-scroll tfoot th,
.table-scroll tfoot td {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  color: #fff;
  z-index:4;
}

.table-scroll-readmore-outer{
  width: 100%;
  @include display-flex;
  justify-content: center;
}

.table-scroll-readmore{
  @include mz-font-size-height(14px);
  color:#2f80ed;
  text-decoration: underline;
  text-align: center;
  cursor: pointer;
  padding: mz-get-space-from-base(1);
}