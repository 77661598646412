.post-review-box, .post-review-complete{
  @include mz-position-center-y;
  position: fixed;
  width: calc(100% - 32px);
  margin: mz-get-space-from-base(2);
  background: #ffffff;
  border-radius: 8px;
  color:$mz-gray-root;
  opacity: 0;
  z-index: 1001;
  transition: $mz-transition-time-default;
  pointer-events: none;
  overflow: hidden;
  padding: mz-get-space-from-base(5) mz-get-space-from-base(2.5) mz-get-space-from-base(6);
  text-align: left;

  @include mz-media-breakpoint-up('sm'){
    margin: mz-get-space-from-base(4);
    width: calc(100% - 64px);
  }

  @include mz-media-breakpoint-up('md'){
    padding: mz-get-space-from-base(8) mz-get-space-from-base(4.5);
    margin: mz-get-space-from-base(8);
    width: calc(100% - 128px);
  }

  @include mz-media-breakpoint-up('lg'){
    padding: mz-get-space-from-base(8) mz-get-space-from-base(10);
    width: 100%;
    max-width: 720px;
    margin: auto;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }

  @include mz-media-breakpoint-up('xl'){
    padding: mz-get-space-from-base(8) 120px;
    max-width: 800px;
  }
}

.post-review-box-wrapper,.post-review-complete-wrapper{
  position: relative;
  @include display-flex;
  flex-direction: column;
}

.post-review-box.is-show,.post-review-complete.is-show{
  opacity: 1;
  pointer-events: all;
}

.post-review-box-title{
  @include mz-text-size(32px);
  font-weight: bold;
  color:$mz-gray-root;
}

.post-review-box-rating-star{
  padding: mz-get-space-from-base(3) 0;
}

.post-review-box-rating-star .wp-review-user-rating .review-result-wrapper span{
  padding:0;
}

.post-review-box-rating-star .review-result-wrapper i,  .post-review-box-rating-star .wp-review-user-rating .review-result-wrapper .review-result i{
  font-size: 24px!important;
  padding-left:mz-get-space-from-base(2.5);

  @include mz-media-breakpoint-up('lg'){
    font-size: 32px!important;
  }
}

.post-review-box-form-body{
  width: 100%;
}

.post-review-box-form-body .mz-form-control-label{
  font-weight: bold;
  padding-bottom: mz-get-space-from-base(1);
}

.post-review-box .mz-form-control-group{
  //padding-bottom: mz-get-space-from-base(2);
}

.post-review-box-form-button-group{
  @include display-flex(nowrap);
  justify-content: space-between;
  width: 100%;

  @include mz-media-breakpoint-up('md'){
    justify-content: flex-end;
    @include display-flex(nowrap);
  }
}

.post-review-box-form-button-group {
  margin: mz-get-space-from-base(0.5) 0;
}

.post-review-box-form-button-group button{
  width: 100%;

  @include mz-media-breakpoint-up('md'){
    width: 136px;
    padding: 0 mz-get-space-from-base(2);
  }
}

.post-review-box-form-button-group button:first-child{
  margin-right: mz-get-space-from-base(1.75);
}

//######################## Review complete #########################//
.post-review-complete{
  justify-content: center;
  z-index: 1002;
  text-align: center;
}

.post-review-complete-close{
  position: absolute;
  content:"";
  top: -30px;
  right: -10px;
  text-align: right;
  cursor: pointer;
  font-size: 32px;

  @include mz-media-breakpoint-up('md'){
    top: -48px;
    right: -20px;
  }

  @include mz-media-breakpoint-up('lg'){
    right: -64px;
  }
  @include mz-media-breakpoint-up('xl'){
    right: -104px;
  }
}

.post-review-complete-head-img{
  @include mz-media-breakpoint-up('md'){
    max-width:400px;
  }
}

.post-review-complete-title{
  @include mz-heading-text-size('h2');
  font-weight: bold;
  color:$mz-primary-root;
  padding: mz-get-space-from-base(2) 0;

  @include mz-media-breakpoint-up('xl'){
    @include mz-font-size-height(48px);
  }
}

.post-review-complete-des{
  @include mz-font-size-height(16px);

  @include mz-media-breakpoint-up('md'){
    @include mz-font-size-height(24px);
    max-width: 550px;
  }

  @include mz-media-breakpoint-up('xl'){
    @include mz-font-size-height(28px);
  }
}