/*
DOC:
! - Thứ tự của các breakpoint key trong map rất quan trọng, không tự ý sửa đổi
*/

// ######################################################
// Grid
// ######################################################
$mz-breakpoint-xs-min: 0;
$mz-breakpoint-sm-min: 592px;
$mz-breakpoint-md-min: 752px;
$mz-breakpoint-lg-min: 992px;
$mz-breakpoint-xl-min: 1280px;
$mz-breakpoint-xxl-min: 1352px;
$mz-breakpoint-xxxl-min: 1432px;
$mz-breakpoint-default: $mz-breakpoint-xs-min;
$mz-breakpoint-key-default: 'xs';

$mz-map-breakpoint: (
  'xs': $mz-breakpoint-xs-min,
  'sm': $mz-breakpoint-sm-min,
  'md': $mz-breakpoint-md-min,
  'lg': $mz-breakpoint-lg-min,
  'xl': $mz-breakpoint-xl-min,
  'xxl': $mz-breakpoint-xxl-min,
  'xxxl': $mz-breakpoint-xxxl-min,
);

$mz-map-breakpoint-key: map-keys($mz-map-breakpoint);

// ######################################################
// Side space
// ######################################################
$mz-side-space-xs: 16px;
$mz-side-space-sm: 16px;
$mz-side-space-md: 48px;
$mz-side-space-lg: 88px;
$mz-side-space-xl: 88px;
$mz-side-space-xxl: 88px;
$mz-side-space-xxxl: 88px;
$mz-side-space-default: $mz-side-space-xs;

$mz-map-side-space: (
  'xs': $mz-side-space-xs,
  'sm': $mz-side-space-sm,
  'md': $mz-side-space-md,
  'lg': $mz-side-space-lg,
  'xl': $mz-side-space-xl,
  'xxl': $mz-side-space-xxl,
  'xxxl': $mz-side-space-xxxl,
);

// ######################################################
// Container size
// ######################################################
$mz-container-xs-width: 100%;
$mz-container-sm-width: 100%;
$mz-container-md-width: 728px;
$mz-container-lg-width: 968px;
$mz-container-xl-width: 1256px;
$mz-container-xxl-width: 1328px;
$mz-container-xxxl-width: 1408px;
$mz-container-width-default: $mz-container-xs-width;

$mz-map-container-width: (
  'xs': $mz-container-xs-width,
  'sm': $mz-container-sm-width,
  'md': $mz-container-md-width,
  'lg': $mz-container-lg-width,
  'xl': $mz-container-xl-width,
  'xxl': $mz-container-xxl-width,
  'xxxl': $mz-container-xxxl-width,
);

// ######################################################
// Scrollbar
// ######################################################
$mz-window-scrollbar-width: 0; // Chiều rộng của thanh scrollbar native của cửa sổ browser

// ######################################################
// Space
// ######################################################
$mz-space-base: 8px; // ! Sử dụng này rồi nhân lên * x để lấy số chính xác. Sử dụng cho margin,padding
$mz-map-space-rate: (
  0: 0,
  1: 0.25,
  2: 0.5,
  3: 0.75,
  4: 1,
  5: 1.25,
  6: 1.5,
  7: 1.75,
  8: 2,
  9: 2.25,
  10: 2.5,
  11: 2.75,
  12: 3
);

// ######################################################
// Grid colum
// ######################################################
$mz-grid-total-column: 12;