$_arrFormSize: 'xl','lg','md','sm','xs';
$_arrBrdSize: 'circle','round','xl','lg','md','sm','xs';
$_padding-select-box: mz-get-space-from-base(1.5); // canh cho dropdown icon
input {
  &.is-read-only, &:read-only{
  background: mz-get-gray-color-with-level('lightest');
  color: mz-get-gray-color-with-level('light');
  }
}

@each $_formSize in $_arrFormSize {
  // Form control
  .mz-form-control-#{$_formSize}{
    $height: mz-get-height-button-form($_formSize);
    $font-size: mz-get-btn-font-size($_formSize);

    height: $height;
    font-size: $font-size;
    line-height: mz-get-line-height-from-font-size($font-size);
    position: relative;

    .mz-form-control{

      &__input{
        height: 100%;
        width: 100%;
        padding:0 mz-get-space-from-base(1.5);
        border-radius: $mz-brd-default;
        @include mz-get-border-gray-color('lighter');
        transition: $mz-transition-time-default;
        color: $mz-gray-root;
        background: #ffffff;

        &:focus{
          @include mz-get-border-gray-color('root');
          outline: none;
        }

        &.is-disabled, &:disabled{
          pointer-events: none;
          cursor: not-allowed;
        }
        &.is-disabled, &:disabled{
          background: mz-get-gray-color-with-level('lightest');
          color: $mz-gray-light;
        }

        // Create border radius class
        @each $_brdSize in $_arrBrdSize {
          &.mz-brd-#{$_brdSize}{
            border-radius: mz-get-brd($_brdSize);
          }
        }

        &.mz-form-control-line{
          padding:0;
          border:solid 1px transparent;
          border-bottom: solid 1px mz-get-gray-color-with-level('lighter');
          border-radius: 0px;
          &:focus{
            border-bottom: solid 1px mz-get-gray-color-with-level('root');
            // &::placeholder{
            //   color:mz-get-gray-color-with-level('root');
            // }
          }
        }
      }
    }

    button{
      position: absolute;
      content: "";
      right: 0;
      top:50%;
      transform: translateY(-50%);
      width: $height;
      height: $height;
    }
  }// end Form control

  // Select control
  .mz-select-control-#{$_formSize}{
    height: mz-get-height-button-form($_formSize);
    $font-size: mz-get-btn-font-size($_formSize);
    line-height: mz-get-line-height-from-font-size($font-size);
    font-size: $font-size;
    position: relative;

    .mz-select-control{

      &__input{
        height: 100%;
        width: 100%;
        padding:0 $_padding-select-box*2 0 $_padding-select-box;
        border-radius: $mz-brd-default;
        @include mz-get-border-gray-color('lighter');
        transition: $mz-transition-time-default;
        color: mz-get-gray-color-with-level('root');
        background: #ffffff;
        font-size: $font-size;
        line-height: mz-get-line-height-from-font-size($font-size);

        &:focus{
          @include mz-get-border-gray-color('root');
          outline: none;
        }

        &.is-disabled, &:disabled{
          pointer-events: none;
          cursor: not-allowed;
        }
        &.is-disabled, &:disabled{
          background: mz-get-gray-color-with-level('lightest');
          color: mz-get-gray-color-with-level('light');
        }

        // Create border radius class
        @each $_brdSize in $_arrBrdSize {
          &.mz-brd-#{$_brdSize}{
            border-radius: mz-get-brd($_brdSize);
          }
        }

        &.mz-select-control-line{
          padding:0;
          border:solid 1px transparent;
          border-bottom: solid 1px mz-get-gray-color-with-level('lighter');
          border-radius: 0px;
          &:focus{
            border-bottom: solid 1px mz-get-gray-color-with-level('root');
            // &::placeholder{
            //   color:mz-get-gray-color-with-level('root');
            // }
          }
        }
      }
    }
    // Create custom dropdown icon
    &:before{
      position: absolute;
      @include mz-get-font-awesome-icon('\f107','light');
      top:50%;
      transform: translateY(-50%);
      right:$_padding-select-box;
      pointer-events: none;
    }
  }// end Select control
}

.mz-select-control{
  @include mz-make-row;
  margin:0;
  flex-direction: row;
}

.mz-select-control-line:before{
  right:0;
}

.mz-select-control-label{
  @include mz-font-size-height(14px);
  font-weight: bold;
  color: $mz-gray-root;
  padding: mz-get-space-from-base(1) 0;
}

select{
  // hide dropdown icon
  -o-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.mz-form-control-textarea{
  height: mz-get-space-from-base(12);
  width: 100%;
  padding:mz-get-space-from-base(1.5);
  border-radius: $mz-brd-default;
  @include mz-get-border-gray-color('lighter');
  transition: $mz-transition-time-default;
  color: $mz-gray-root;
  background: #ffffff;
  resize: none;

  &:focus{
    @include mz-get-border-gray-color('root');
    outline: none;
  }

  &.is-disabled, &:disabled{
    pointer-events: none;
    cursor: not-allowed;
    background: mz-get-gray-color-with-level('lightest');
    color: $mz-gray-light;
  }

  &.mz-form-control-line{
    padding:0;
    border:solid 1px transparent;
    border-bottom: solid 1px mz-get-gray-color-with-level('lighter');
    border-radius: 0px;
    transition: $mz-transition-time-default;

    @include placeholder{
      color: $mz-gray-lighter;
    }

    &:focus{
      border-bottom: solid 1px $mz-gray-root;
    }

    // @include placeholder-focus{
    //   color: $mz-gray-root;
    // }
  }
}

.mz-form-control-label{
  padding-bottom: mz-get-space-from-base(1);
}

.mz-form-item{
  width:100%;
  padding-bottom: mz-get-space-from-base(3);
}

.mz-form-row{
  @include display-flex(nowrap);
  width: 100%;
}

.mz-form-mutiple-col{

  @include mz-media-breakpoint-up('md'){
    @include display-flex(nowrap);
    align-items: flex-start;
  }

  .mz-form-control{
    width: 100%;
  }

  .mz-select-control{
    width: 100%;
  }
}
.mz-form-single-col{
  @include display-flex(nowrap);
  justify-content: center;

  .mz-form-control{
    width: 100%;
  }

  .mz-select-control{
    width: 100%;
  }
}

.mz-form-phone-email{
  @include mz-media-breakpoint-up('md'){
    @include display-flex(nowrap);
    justify-content: space-between;
  }
}

.mz-form-phone-email .mz-form-item{
  @include mz-media-breakpoint-up('md'){
    &:first-child{
      margin-right: mz-get-space-from-base(2);
    }
  }
}

.mz-select-date{
  margin-right: mz-get-space-from-base(1);

  &:last-child{
    margin-right: 0;
  }
}

.mz-form-control-des{
  @include mz-text-size(13px);
  color: $mz-gray-light;
  padding-top: mz-get-space-from-base(1);
}

// ######################### Check box ############################
// ######################### Check box ############################

.mz-checkbox-control{
  @include display-flex(nowrap);
  width: 100%;
  align-items: baseline;
  justify-content: flex-start;
}

.mz-checkbox-input-wrapper{
  display: flex;
}

.mz-checkbox-label{
  margin-left: mz-get-space-from-base(1);
}

.mz-checkbox-input-wrapper {
  display: block;
  position: relative;
  padding-left: mz-get-space-from-base(3);
  cursor: pointer;
  @include mz-font-size-height(14px);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mz-checkbox-input-wrapper .mz-checkbox-input {
  display: none;
}

.mz-checkbox-input-style {
  position: absolute;
  top: 3px;
  left: 0;
  height: 16px;
  width: 16px;
  background:transparent;
  border: solid 1px $mz-gray-light;
  //overflow: hidden;
}

.mz-checkbox-input-wrapper:hover .mz-checkbox-input ~ .mz-checkbox-input-style {
}

.mz-checkbox-input-wrapper .mz-checkbox-input:checked ~ .mz-checkbox-input-style {
  border: solid 1px $mz-primary-root;
}

.mz-checkbox-input-style:after {
  content: "";
  position: absolute;
  display: none;
  height: 16px;
  width: 16px;
  border-radius: 4px;
  top:-1px;
  left:-1px;
  align-items: center;
  justify-content: center;
}

.mz-checkbox-input-wrapper .mz-checkbox-input:checked ~ .mz-checkbox-input-style:after {
  display: flex;
}

.mz-checkbox-input-wrapper .mz-checkbox-input-style:after {
  @include mz-get-font-awesome-icon('\f00c','regular');
  color:#ffffff;
  font-size: 10px;
  background: $mz-primary-root;
}

// ######################### Check box ############################
// ######################### Check box ############################

// ######################### mz-select-file ############################
// ######################### mz-select-file ############################
input[type="file"] {
  display: none;
}

.mz-form-select-file-line{
  border-bottom: solid 1px mz-get-gray-color-with-level('lighter');
  border-radius: 0px;
  height: 100%;
  position: relative;
  display: flex;
}

.select-file-label{
  width: 100%;
  height: 100%;
  @include display-flex(nowrap);
  justify-content: center;
  z-index: 1;
  margin:0;
}

.select-file-label span{
  width: 100%;
  color: $mz-gray-light;
  @include mz-text-size(14px);
  height: 20px;
  overflow: hidden;

  &.is-active{
    color: $mz-gray-root;
  }
}

.select-file-button{
  margin:0 0 0 auto;
  width: 100%;
  max-width: 100px;
}

// ######################### mz-select-file ############################
// ######################### mz-select-file ############################

.text-error-validate{
  width: 100%;
  height: 20px;
  top: 100%;
  left: 0;
  color: $mz-primary-root;
  font-size: 13px;
}

.mz-text-validate{
  display: flex;
  padding-top: mz-get-space-from-base(1);
  color: $mz-primary-root;
  @include mz-text-size(12px);

  @include mz-media-breakpoint-up('lg'){
    @include mz-text-size(13px);
  }
}