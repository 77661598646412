.fancybox-is-open .fancybox-bg {
  background: #000;
  opacity: 0.9;
}

.fancybox-thumbs {
  top: auto;
  width: auto;
  bottom: 0;
  left: 0;
  right : 0;
  height: mz-get-space-from-base(9);
  padding: mz-get-space-from-base(1) mz-get-space-from-base(2);
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.3);
  text-align: center;

  ul {
    display: inline-block;
    width: auto;

    li {
      width: mz-get-space-from-base(7);
      height: mz-get-space-from-base(7);
      display: flex;
      background-color: #fff;
      background-position: center;
      background-size: contain;
      max-width: none!important;
      max-height: none!important;
      margin: 0 mz-get-space-from-base(1) 0 0;
      border-radius: $mz-brd-md;
      box-shadow: 0 0 0 1px rgba(#000,0.1) inset;
      transition: $mz-transition-time-default;

      &::before {
        border: 0;
      }

      &:hover, &.fancybox-thumbs-active {
        box-shadow: 0 0 0 3px $mz-primary-root inset;
      }

      &:last-child {
        margin-right: 0;
      }
    }// li
  }// ul
}// .fancybox-thumbs

.fancybox-show-thumbs .fancybox-inner {
  right: 0;
  bottom: 70px;
}