.rating-box{
  @include display-flex;
  justify-content: center;
  padding: mz-get-space-from-base(3) 0;

  @include mz-media-breakpoint-up('lg'){
    padding: mz-get-space-from-base(1.5) 0;
    position: sticky;
    top: 160px;
  }
}

.rating-box-header{
  @include mz-media-breakpoint-up('md'){
    @include display-flex(nowrap);
    justify-content: space-between;
    align-items: flex-end;
  }

  @include mz-media-breakpoint-up('lg'){
    flex-wrap: wrap;
  }
}

.rating-box-text{

  @include mz-media-breakpoint-up('md'){
    padding: mz-get-space-from-base(3) 0;
  }

  @include mz-media-breakpoint-up('lg'){
    padding: 0;
  }
}

.rating-box-title{
  @include mz-heading-text-size('h5');
  color: $mz-secondary-dark;
  font-weight: bold;
  padding-bottom: mz-get-space-from-base(1.75);
  width: 100%;

  @include mz-media-breakpoint-only('lg'){
    @include mz-font-size-height(24px);
  }
}

.rating-box-des{
  @include mz-text-size(14px);
  color: $mz-info-dark;
}

.rating-box-cover-image{
  padding: mz-get-space-from-base(3) 0;

  @include mz-media-breakpoint-up('md'){
    margin-left:mz-get-space-from-base(3);
    min-width: 39%;
    transform: translateY(12px);
  }

  @include mz-media-breakpoint-up('lg'){
    margin-left:0;
    transform: translateY(0);
  }
}

.rating-box-body{
  padding: 0 mz-get-space-from-base(2);
  width: 100%;

  @include mz-media-breakpoint-up('md'){
    padding: 0;
  }
}

.rating-box-rating-star{
  width: 100%;
  @include display-flex;
  justify-content: center;
  @include mz-font-size-height(24px);
  position: relative;

  @include mz-media-breakpoint-up('lg'){
    justify-content: flex-end;
  }
}

.rating-box-rating-star:before{
  @include mz-position-center-y;
  height: .6px;
  width: 100%;
  background: $mz-gray-lighter;
}

.rating-box-rating-star{
  .star-rating-wrapper{
    position: relative;
    z-index: 2;
    background: #ffffff;
  }
}

.rating-box-rating-star{
  i{
    margin: mz-get-space-from-base(.5);
    @include mz-font-size-height(24px);
  }
}

.rating-box-suggest{
  padding: mz-get-space-from-base(3) 0;
  @include mz-font-size-height(16px);
  color: $mz-info-dark;
  border-bottom: solid .6px $mz-gray-lighter;
  margin-bottom: mz-get-space-from-base(3);

  @include mz-media-breakpoint-up('xl'){
    @include mz-font-size-height(18px)
  }
}

.rating-box-button-review{
  text-align: center;
  cursor: pointer;
}

.rating-box-button-review button{
  width: 100%;

  @include mz-media-breakpoint-up('md'){
    width: 244px;
  }

  @include mz-media-breakpoint-up('xl'){
    width: 90%;
    max-width: 286px;
  }
}