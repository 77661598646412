// ######################### Search flexible ###########################
.search-flexible{
  margin-left:auto;

  @include mz-media-breakpoint-up('xl'){
    position: relative;
    @include display-flex(nowrap);
    justify-content: flex-end;
    flex-grow: 2;
  }

  @include mz-media-breakpoint-only('md'){
    display: none;
  }
}

.search-flexible .search-flexible-icon{
  cursor: pointer;
  font-size: 18px;
  color: $mz-gray-root;
  @include display-flex(nowrap);
}

.search-flexible .search-flexible-icon i{
  padding: mz-get-space-from-base(1.25);
  height: 40px;
  width: 40px;
  @include display-flex;
  justify-content: center;
}

.search-flexible .search-flexible-icon:hover{
  color: $mz-primary-root;

  span{
    color: $mz-primary-root;
  }
}

.search-flexible .search-label{
  font-size: 14px;
  line-height: mz-get-line-height-from-font-size(14px);
  color: $mz-gray-root;

  @include mz-media-breakpoint-down('lg'){
    display: none;
  }
}

.search-flexible .search-flexible-bar{
  transition: $mz-transition-time-default;
  position: absolute;
  left: 0px;
  top: 0px;
  padding-right: mz-get-space-from-base(2);

  opacity: 0;
  cursor: none;
  pointer-events:none;
  width: 100%;
  @include display-flex(nowrap);
  justify-content: space-between;
  background: #ffffff;
  z-index:100;
  height: 100%;

  @include mz-media-breakpoint-up('lg'){
  }

  @include mz-media-breakpoint-up('xl'){
    //position: unset;
  }
}
.search-flexible .search-flexible-bar.active{
  opacity: 1;
  cursor: pointer;
  pointer-events: all;
}

.search-flexible .search-flexible-bar .btn-search{
  position: absolute;
  top:50%;
  transform: translateY(-50%);
  right:0;
}

.search-flexible .search-flexible-bar .btn-search:hover{
  color: $mz-primary-root;
}

.search-flexible .search-flexible-bar .mz-form-control{
  flex-grow: 4;
}

.search-flexible .mz-btn{
  box-shadow:none;
}

.search-flexible .btn-exit{
  justify-content: flex-start;

  .mz-btn-icon{
    font-size: 16px;
  }
}

.search-flexible .mz-form-control__input{
  background: $mz-gray-lightest;
  color: $mz-gray-root;
  border: none!important;
  padding-right: mz-get-space-from-base(6);
}

.icon-btn-exit-on-xs{
  @include mz-media-breakpoint-up('xl'){
    display:none;
  }
}

.icon-btn-exit-on-xl{
  @include mz-media-breakpoint-down('lg'){
    display:none;
  }
}
// ######################### Search flexible ###########################

// ########################### Search line #############################
.mz-search-line{
  position: relative;
  max-width: 938px;
  margin:auto;
}

.mz-search-line-control{
  position: relative;
  height: mz-get-space-from-base(9);
  background: #ffffff;
}

.mz-search-line-input{
  position: relative;
  z-index: 1;
  border: solid 1px transparent;
  border-bottom: solid 1px $mz-gray-lighter;
  height: 100%;
  width: 100%;
  transition: $mz-transition-time-default;
  background: #ffffff;
  color: $mz-gray-root;
  font-weight: bold;
  @include mz-text-size(13px);
  padding-right: mz-get-space-from-base(3);
  outline: unset!important;
  transition: $mz-transition-time-default;

  @include placeholder{
    color: $mz-gray-lighter;
  }

  @include placeholder-focus{
    color: $mz-gray-light;
  }

  @include mz-media-breakpoint-up('md'){
    padding-right: mz-get-space-from-base(9);
    @include mz-text-size(16px);
  }
}

.mz-search-line-input:focus{
  border-bottom: solid 1px $mz-gray-root;
}

.mz-search-line-status{
  @include mz-position-center-y;
  z-index: 2;
  right:0;
  font-size: 24px;
  width: 40px;
  height: 40px;
  @include display-flex;
  justify-content: flex-end;
  color: $mz-gray-light;
}

.mz-search-line-status i{
  display: none;
  transition: $mz-transition-time-default;
  margin-right: mz-get-space-from-base(1);
}

.mz-search-paste-link{
  @include mz-position-center-y;
  display: block;
  @include mz-text-size(12px);
  color: $mz-secondary-root;
  margin:0;
  background: #ffffff;
  cursor: pointer;
  width: 70px;
}

.mz-search-line-status{
  &:hover, &:active{
    .mz-search-line-search-icon{
      color: $mz-primary-root;
    }
  }
}

.mz-search-line-status.is-ready .mz-search-line-search-icon{
  display: block;
  cursor: pointer;
}

.mz-search-line-status.is-loading .mz-search-line-loading-icon{
  display: block;
  pointer-events: unset;
}

.mz-search-line-status.is-success .mz-search-line-success-icon{
  display: block;
  color: $mz-success-root;
  pointer-events: unset;
}

.mz-search-line-status.is-fail .mz-search-line-fail-icon{
  display: block;
  color: $mz-danger-root;
  pointer-events: unset;
}

//.mz-search-line-input:focus + .mz-search-line-status{
//  .mz-search-paste-link{
//    display: block;
//    cursor: pointer;
//  }
//
//  i{
//    // display: none;
//  }
//}

// ########################### Search line #############################

// ########################## Search round #############################

.mz-search-round{
  position: relative;
  width: 100%;
  max-width: 938px;
  margin:auto;
}

$_border-radius-search-round: 28px;

.mz-search-round-control{
  width: 100%;
  position: relative;
  height: mz-get-space-from-base(7);
  @include display-flex;
  flex-wrap: nowrap;
  box-shadow: 0 16px 32px -12px rgba(61, 63, 64, 0.2);
  border: solid 1px rgba(193, 198, 201, 0.5);
  overflow: hidden;
  background: #ffffff;

  @include mz-media-breakpoint-up('md'){
    border-radius: $_border-radius-search-round;
  }
}

.mz-search-round-input-wrapper{
  position: relative;
  height: mz-get-space-from-base(7);
  flex-grow: 9;
}

.mz-search-round-input{
  z-index: 1;
  border: unset;
  height: 100%;
  width: 100%;
  transition: $mz-transition-time-default;
  background: #ffffff;
  color: $mz-gray-root;
  font-weight: bold;
  @include mz-text-size(12px);
  padding:0 mz-get-space-from-base(2);
  outline: unset!important;
  transition: $mz-transition-time-default;

  @include placeholder{
    color: $mz-gray-lighter;
  }

  @include placeholder-focus{
    color: $mz-gray-light;
  }

  @include mz-media-breakpoint-up('md'){
    padding-left: mz-get-space-from-base(3);
    padding-right: mz-get-space-from-base(9);
    @include mz-text-size(16px);
  }
}

.mz-search-round-status{
  @include mz-position-center-y;
  z-index: 2;
  right:0;
  font-size: 24px;
  width: 32px;
  height: 32px;
  @include display-flex;
  justify-content: flex-end;
  color: $mz-gray-light;
  background: #ffffff;
  border-radius: 50%;
  pointer-events: none;
}

.mz-search-round-status i{
  display: none;
  transition: $mz-transition-time-default;
  margin-right: mz-get-space-from-base(1);
}

.mz-search-round-status{
  &:hover, &:active{
    .mz-search-round-search-icon{
      color: $mz-primary-root;
    }
  }
}

.mz-search-round-status.is-ready .mz-search-round-search-icon{
  display: block;
}

.mz-search-round-status.is-loading .mz-search-round-loading-icon{
  display: block;
  pointer-events: unset;
}

.mz-search-round-status.is-success .mz-search-round-success-icon{
  display: block;
  color: $mz-success-root;
  pointer-events: unset;
}

.mz-search-round-status.is-fail .mz-search-round-fail-icon{
  display: block;
  color: $mz-danger-root;
  pointer-events: unset;
}

.search-bar .search-bar-icon.is-ready .mz-search-line-search-icon, .search-flexible .mz-btn-icon.is-ready .mz-search-line-search-icon{
  display: block;
}

.search-bar .search-bar-icon.is-loading .mz-search-line-loading-icon, .search-flexible .mz-btn-icon.is-loading .mz-search-line-loading-icon{
  display: block;
  pointer-events: unset;
}

.search-bar .search-bar-icon.is-success .mz-search-line-success-icon, .search-flexible .mz-btn-icon.is-success .mz-search-line-success-icon{
  display: block;
  color: $mz-success-root;
  pointer-events: unset;
}

.search-bar .search-bar-icon.is-fail .mz-search-line-fail-icon, .search-flexible .mz-btn-icon.is-fail .mz-search-line-fail-icon{
  display: block;
  color: $mz-danger-root;
  pointer-events: unset;
}

.mz-search-round-input:focus + .mz-search-round-status{
  .mz-search-paste-link{
    display: block;
    cursor: pointer;
  }

  i{
    display: none;
  }
}

.mz-search-round-confirm{
  position: relative;
  flex-grow: 1;
  height: mz-get-space-from-base(7);
  overflow: hidden;
  @include display-flex;
  justify-content: space-around;
  @include mz-text-size(14px);
  min-width: 65px;
  cursor: pointer;
  padding: 0 mz-get-space-from-base(2);

  @include mz-media-breakpoint-up('md'){
    @include mz-text-size(16px);
  }
}

.mz-search-round-confirm:before{
  position: absolute;
  content:"";
  background: $mz-primary-root;
  width: 300px;
  height: 300px;
  left: 22px;
  transform: rotate(-70deg);
  transition: $mz-transition-time-default;
}

.mz-search-round-confirm:hover{

  &:before{
    background: $mz-primary-dark;
  }
}

.mz-search-round-confirm-button{
  position: relative;
  z-index: 2;
  color: #ffffff;
  @include display-flex;
  flex-wrap: nowrap;
  transform:translateX(8px);

  @include mz-media-breakpoint-up('md'){
  }
}

.mz-search-round-confirm-icon{
  margin-right: mz-get-space-from-base(1);
  display: block;
  font-size: 22px;
}

.mz-search-round-confirm-label{
  font-weight: bold;

  @include mz-media-breakpoint-down ('md'){
    display: none;
  }
}

// ########################## Search round #############################