.social-button-wrapper{
  @include display-flex(wrap);
  justify-content: flex-start;
  margin: 0 mz-get-space-from-base(-0.25);
}

.social-button-item{
  @include display-flex(nowrap);
  height: mz-get-space-from-base(3.5);
  margin: mz-get-space-from-base(.25);
  padding:0 mz-get-space-from-base(.5);
  background: $mz-secondary-dark;
  color:#ffffff;
  @include mz-font-size-height(8px);
  max-width: 100%;

  @include mz-media-breakpoint-up('lg'){
    @include mz-font-size-height(10px);
  }
}

.social-button-item-icon,.social-button-item-text{
  position: relative;
  padding:0 mz-get-space-from-base(.5);

  @include mz-media-breakpoint-up('lg'){
    padding:0 mz-get-space-from-base(1);
  }
}

.social-button-item-text{
  &:before{
    @include mz-position-center-y;
    left:0;
    height: 80%;
    width: 1px;
    background: $mz-gray-lightest;
    z-index: 1;
    opacity: 0.5;
  }
}

.social-button-facebook{
  background:#3b5998;
}

.social-button-twitter{
  background:#1dcaff;
}

.social-button-google{
  background:#df4b37;
}

.social-button-pinterest{
  background:#cd2029;
}