.tooltip{
  @include mz-font-size-height(14px);
}

.tooltip .arrow{
  opacity: 0.9;
}

.tooltip-inner{
  padding:mz-get-space-from-base(2);
  opacity: 0.9;
  background: $mz-gray-root;
  color:#ffffff;
  border-radius: mz-get-space-from-base(1);
}

.mz-tooltip-wrapper{
  position: relative;
  padding-right: mz-get-space-from-base(2.5);
}

.mz-tooltip{
  @include mz-position-center-y;
  right: 0;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #ffffff;
  color:$mz-primary-root;
  border: solid 1px $mz-primary-root;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover{
    background: $mz-primary-root;
    color:#ffffff;
    border: solid 1px transparent;
  }
}

.mz-tooltip{
  i{
    font-size: 8px;
  }
}