* {
  box-sizing: border-box;
}

a {
  &:hover {
    text-decoration: none;
  }
}

img {
  max-width: 100%;
  height: auto;
}

figure {
  width: fit-content !important;
  width: -moz-fit-content !important;
  max-width: 100% !important;
}