@each $_breakpoint-key in $mz-map-breakpoint-key {
  @include mz-media-breakpoint-up($_breakpoint-key) {
    $_breakpoint-infix-class: mz-get-breakpoint-infix-class($_breakpoint-key);

    .mz-flex#{$_breakpoint-infix-class}-row            { flex-direction: row !important; }
    .mz-flex#{$_breakpoint-infix-class}-column         { flex-direction: column !important; }
    .mz-flex#{$_breakpoint-infix-class}-row-reverse    { flex-direction: row-reverse !important; }
    .mz-flex#{$_breakpoint-infix-class}-column-reverse { flex-direction: column-reverse !important; }

    .mz-flex#{$_breakpoint-infix-class}-wrap         { flex-wrap: wrap !important; }
    .mz-flex#{$_breakpoint-infix-class}-nowrap       { flex-wrap: nowrap !important; }
    .mz-flex#{$_breakpoint-infix-class}-wrap-reverse { flex-wrap: wrap-reverse !important; }
    .mz-flex#{$_breakpoint-infix-class}-fill         { flex: 1 1 auto !important; }
    .mz-flex#{$_breakpoint-infix-class}-grow-0       { flex-grow: 0 !important; }
    .mz-flex#{$_breakpoint-infix-class}-grow-1       { flex-grow: 1 !important; }
    .mz-flex#{$_breakpoint-infix-class}-shrink-0     { flex-shrink: 0 !important; }
    .mz-flex#{$_breakpoint-infix-class}-shrink-1     { flex-shrink: 1 !important; }

    .mz-justify-content#{$_breakpoint-infix-class}-start   { justify-content: flex-start !important; }
    .mz-justify-content#{$_breakpoint-infix-class}-end     { justify-content: flex-end !important; }
    .mz-justify-content#{$_breakpoint-infix-class}-center  { justify-content: center !important; }
    .mz-justify-content#{$_breakpoint-infix-class}-between { justify-content: space-between !important; }
    .mz-justify-content#{$_breakpoint-infix-class}-around  { justify-content: space-around !important; }

    .mz-align-items#{$_breakpoint-infix-class}-start    { align-items: flex-start !important; }
    .mz-align-items#{$_breakpoint-infix-class}-end      { align-items: flex-end !important; }
    .mz-align-items#{$_breakpoint-infix-class}-center   { align-items: center !important; }
    .mz-align-items#{$_breakpoint-infix-class}-baseline { align-items: baseline !important; }
    .mz-align-items#{$_breakpoint-infix-class}-stretch  { align-items: stretch !important; }

    .mz-align-content#{$_breakpoint-infix-class}-start   { align-content: flex-start !important; }
    .mz-align-content#{$_breakpoint-infix-class}-end     { align-content: flex-end !important; }
    .mz-align-content#{$_breakpoint-infix-class}-center  { align-content: center !important; }
    .mz-align-content#{$_breakpoint-infix-class}-between { align-content: space-between !important; }
    .mz-align-content#{$_breakpoint-infix-class}-around  { align-content: space-around !important; }
    .mz-align-content#{$_breakpoint-infix-class}-stretch { align-content: stretch !important; }

    .mz-align-self#{$_breakpoint-infix-class}-auto     { align-self: auto !important; }
    .mz-align-self#{$_breakpoint-infix-class}-start    { align-self: flex-start !important; }
    .mz-align-self#{$_breakpoint-infix-class}-end      { align-self: flex-end !important; }
    .mz-align-self#{$_breakpoint-infix-class}-center   { align-self: center !important; }
    .mz-align-self#{$_breakpoint-infix-class}-baseline { align-self: baseline !important; }
    .mz-align-self#{$_breakpoint-infix-class}-stretch  { align-self: stretch !important; }
  }
}
