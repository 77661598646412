.si {
  @include si;
  display: inline-block;
  vertical-align: middle;
  font-style: normal;
}


.-si-logo-amazon-white {
  background-position: -5px -5px;
  width: 158px;
  height: 32px;
}

.-si-logo-kohls-white {
  background-position: -5px -47px;
  width: 137px;
  height: 21px;
}

.-si-logo-walmart-white {
  background-position: -5px -78px;
  width: 133px;
  height: 32px;
}

.-si-store-ios {
  background-position: -5px -120px;
  width: 130px;
  height: 40px;
}

.-si-store-android {
  background-position: -173px -5px;
  width: 130px;
  height: 40px;
}

.-si-bo-cong-thuong {
  background-position: -173px -55px;
  width: 127px;
  height: 48px;
}

.-si-safe-web {
  background-position: -5px -170px;
  width: 119px;
  height: 48px;
}

.-si-payment-visa {
  background-position: -5px -228px;
  width: 102px;
  height: 58px;
}

.-si-payment-payoo {
  background-position: -117px -228px;
  width: 101px;
  height: 58px;
}

.-si-payment-master {
  background-position: -313px -5px;
  width: 92px;
  height: 58px;
}

.-si-logo-ebay-white {
  background-position: -313px -73px;
  width: 78px;
  height: 32px;
}

.-si-vip-silver {
  background-position: -313px -115px;
  width: 76px;
  height: 21px;
}

.-si-vip-platinum {
  background-position: -313px -146px;
  width: 76px;
  height: 21px;
}

.-si-vip-gold {
  background-position: -313px -177px;
  width: 76px;
  height: 21px;
}

.-si-vip-basic {
  background-position: -313px -208px;
  width: 76px;
  height: 21px;
}

.-si-payment-jcb {
  background-position: -228px -228px;
  width: 64px;
  height: 58px;
}

.-si-order-timeline-4 {
  background-position: -5px -296px;
  width: 60px;
  height: 60px;
}

.-si-order-timeline-3 {
  background-position: -75px -296px;
  width: 60px;
  height: 60px;
}

.-si-order-timeline-2 {
  background-position: -145px -296px;
  width: 60px;
  height: 60px;
}

.-si-order-timeline-1 {
  background-position: -215px -296px;
  width: 60px;
  height: 60px;
}

.-si-logo-bestbuy-white {
  background-position: -313px -239px;
  width: 54px;
  height: 34px;
}

.-si-user-quotation {
  background-position: -377px -239px;
  width: 23px;
  height: 24px;
}

.-si-user-promo-code {
  background-position: -173px -113px;
  width: 24px;
  height: 24px;
}

.-si-user-profile {
  background-position: -207px -113px;
  width: 24px;
  height: 24px;
}

.-si-user-payment-info {
  background-position: -241px -113px;
  width: 24px;
  height: 24px;
}

.-si-user-order {
  background-position: -275px -113px;
  width: 24px;
  height: 24px;
}

.-si-user-logout {
  background-position: -134px -170px;
  width: 24px;
  height: 18px;
}

.-si-user-link {
  background-position: -285px -296px;
  width: 24px;
  height: 24px;
}

.-si-user-follow {
  background-position: -168px -170px;
  width: 24px;
  height: 16px;
}

.-si-user-favorite {
  background-position: -319px -296px;
  width: 24px;
  height: 22px;
}

.-si-user-cross-check {
  background-position: -285px -330px;
  width: 24px;
  height: 24px;
}

.-si-user-change-pass {
  background-position: -319px -330px;
  width: 24px;
  height: 24px;
}

.-si-user-affiliate {
  background-position: -353px -330px;
  width: 24px;
  height: 24px;
}

.-si-flag-vietnam {
  background-position: -202px -170px;
  width: 24px;
  height: 16px;
}

.-si-flag-indonesia {
  background-position: -236px -170px;
  width: 24px;
  height: 16px;
}

.-si-flag-cambodia {
  background-position: -270px -170px;
  width: 24px;
  height: 16px;
}

.-si-null-image {
  background-position: -401px -73px;
  width: 1px;
  height: 1px;
}
