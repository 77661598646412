/*
DOC:
- Sử dụng: https://getbootstrap.com/docs/4.3/utilities/spacing/

TODO:
- Bổ sung cho trường hợp margin âm với ký hiệu `n` trước số rate space. VD: mz-mr-n1 là `-1` rate
*/
@each $_breakpoint-key in $mz-map-breakpoint-key {
  $_breakpoint-infix-class: mz-get-breakpoint-infix-class($_breakpoint-key);

  @include mz-media-breakpoint-up($_breakpoint-key) {
    @each $_rate-key, $_rate-value in $mz-map-space-rate {
      .mz-m#{$_breakpoint-infix-class}-#{$_rate-key} { margin: mz-get-space-from-base($_rate-value) !important; }
      .mz-mx#{$_breakpoint-infix-class}-#{$_rate-key} { margin: 0 mz-get-space-from-base($_rate-value) !important; }
      .mz-my#{$_breakpoint-infix-class}-#{$_rate-key} { margin: mz-get-space-from-base($_rate-value) 0 !important; }
      .mz-mt#{$_breakpoint-infix-class}-#{$_rate-key} { margin-top: mz-get-space-from-base($_rate-value) !important; }
      .mz-mr#{$_breakpoint-infix-class}-#{$_rate-key} { margin-right: mz-get-space-from-base($_rate-value) !important; }
      .mz-mb#{$_breakpoint-infix-class}-#{$_rate-key} { margin-bottom: mz-get-space-from-base($_rate-value) !important; }
      .mz-ml#{$_breakpoint-infix-class}-#{$_rate-key} { margin-left: mz-get-space-from-base($_rate-value) !important; }
    }

    .mz-mx#{$_breakpoint-infix-class}-auto { margin: 0 auto !important; }
    .mz-my#{$_breakpoint-infix-class}-auto { margin: auto 0 !important; }
    .mz-mt#{$_breakpoint-infix-class}-auto { margin-top: auto !important; }
    .mz-mr#{$_breakpoint-infix-class}-auto { margin-right: auto !important; }
    .mz-mb#{$_breakpoint-infix-class}-auto { margin-bottom: auto !important; }
    .mz-ml#{$_breakpoint-infix-class}-auto { margin-left: auto !important; }
  }
}
